//
// Myaccount help page
// --------------------------------------------------
/************Myaccount help page page***************/
.help-banner-pan{
	background-color:$red;
	font-family:$font-family-custom;
	color:$white;
	font-size:17px;
	margin:0;
	article{
		padding:45px 0 10px;
		position:relative;
		
		&:after{
			content:"";
			position:absolute;
			right:10%;
			bottom:0;
			width:185px;
			height:145px;
			background:url(#{$imageUrl}/mobile-icon.png) no-repeat;
			background-size: 100% auto;
		}
	}
}
.banner-heading02{
	font-size:23px;
	margin:0 0 12px;
	font-weight:400;
	
	span{
		display:block;
	}
	a{
		color:$white;
		font-weight:700;
		text-decoration:underline;
		
		&:hover{
			text-decoration:none;
		}
	}
	
}

.gray-container{
	background-color:lighten($black, 95%);
	padding:25px 0 50px;
}

.help-left-pan-heading{
	font-size:18px;
	font-weight:400;
	color:$red;
	padding:0;
	margin:0;
	a{
		color:$red;
		border-left:2px solid $red;
		text-decoration:none;
		padding:40px 0 0 25px;
		display:block;
	}
}
.left-pan-menu{
	@include list-unstyled;
	padding:0;
	margin:0;
	>li{
		display:block;
		margin:0 0 10px;
		>a{
			font-family:$font-family-custom;
			font-size:18px;
			color:lighten($black, 20%);
			padding:10px 0 4px 25px;
			margin:0;
			display:block;
			border-left:1px solid lighten($black, 95%);
			text-decoration:none;
		}
		&:hover,
		&.active{
			>a{
				color:$red;
				border-color:$red;
			}
		}
		ul{
			padding:0 0 0 5px;
			li{
				display:block;
				margin:0;
				a{
					display:block;
					padding:5px 0 5px 20px;
					border-left:1px solid lighten($black, 95%);
					color:lighten($black, 20%);
					font-size:13px;
					text-decoration:none;
					@include transition(all .2s ease-in-out);
				}
				&:hover,
				&.active{
					a{
						color:$red;
						border-color:$red;
					}
				}
			}
		}
	}
		
}


.help-content-box{
	background-color:$white;
	border:1px solid lighten($black, 82%);
	margin:0 0 25px;
	padding:32px 40px 12px;
	color:lighten($black, 20%);
	font-size:13px;
	
	h3{
		font-size:20px;
		font-family:$font-family-custom;
		font-weight:400;
		padding:0 0 18px;
		margin:0 0 15px;
		border-bottom:3px solid lighten($black, 82%);
	}
}



/**Myaccount help page End**/


