//
// Popup
// --------------------------------------------------
.modal-open{
	.modal{

	}
}
.modal-backdrop{
	background-color:$blue;
	&.in{
		@include opacity(.75);
	}
}
.modal-content {
    box-shadow: 0 0 5px rgba($black, 0.2);
	border-radius:0;
}
.modal-body{
	padding:0;
}

.modal-panel{
	width:1170px;
}

.popup-left-pan{
	width:46%;
	padding:35px 50px 20px 20px;
	float:left;
	min-height:480px;
	font-family:$font-family-custom;
	color:$white;
	background: #0095ad;
	background: -moz-linear-gradient(top,  #0095ad 0%, #00435d 100%);
	background: -webkit-linear-gradient(top,  #0095ad 0%,#00435d 100%);
	background: linear-gradient(to bottom,  #0095ad 0%,#00435d 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0095ad', endColorstr='#00435d',GradientType=0 );
	
	.dropdown{
		margin-left:15px;
	}
	
	p{
		font-size:16px;
		margin:0 0 32px;
	}
	
}

.popup-bg-heading{
	font-family:$font-family-custom;
	color:$white;
	font-weight:700;
	font-size:18px;
	text-transform:uppercase;
	background-color:$blue;
	padding:0 15px;
	line-height:34px;
	margin:0 26px 24px 0;
	position:relative;
	
	&:after{
		content:"";
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 34px 0 0 26px;
		border-color: transparent transparent transparent $blue;
		position:absolute;
		left:100%;
		top:0;

	}
}

.popup-heading02{
	font-family:$font-family-custom;
	color:$white;
	font-size:24px;
	font-weight:300;
	text-transform:uppercase;
	margin:0 0 25px 15px;
	
	strong{
		font-weight:700;
	}
}

.credit-drop-link{
	display:block;
	color:$blue;
	padding:22px 65px 20px;
	position:relative;
	background-color:$white;
	text-decoration:none;
	font-family:$font-family-custom;
	font-weight:300;
	margin:0 0 35px;
	border:none;
	background-color:$white;
	width:100%;
	text-align:left;
	
	&:before{
		@include after();
		width:34px;
		height:30px;
		background-position:-210px 0;
		top:50%;
		left:10px;
		margin-top:-15px;
	}
	
	&:after{
		@include after();
		width:30px;
		height:16px;
		background-position:-160px 0;
		top:50%;
		right:32px;
		margin-top:-8px;
	}
}

.credit-dropdown{
	position:absolute;
	margin:0;
	padding:0;
	background-color:lighten($black, 98%);
	left:0;
	top:100%;
	width:100%;
	font-family:$font-family-custom;
	font-weight:300;
	border-radius:0;
	box-shadow:none;
	border:none;
	display:none;
	
	li{
		display:block;
		margin:0;
		border-bottom:1px solid $white;
		
		&:last-child{
			border-bottom:none;
		}
		
		a{
			display:block;
			text-decoration:none;
			color:$blue;
			padding:8px 10px 8px 65px;
			font-weight:300;
			
			.drop-text{
				font-size:32px;
			}
			.drop-text01{
				font-size:18px;
			}
			
			.drop-text02{
				font-size:18px;
			}
			
			&:focus,
			&:hover,
			&.active{
				text-decoration:none;
				outline:none;
				color:$white;
				text-decoration:none;
				outline:none;
				color:$white;
				background-color:$blue;
			}
		}
	}
}

.drop-text{
	font-size:48px;
	line-height:1;
	margin:0 8px 0 0;
}
.drop-text01{
	font-size:23px;
	font-weight:400;
	position:relative;
	top:0;
	margin:0 5px 0 0;
}
.drop-text02{
	font-size:24px;
	position:relative;
	top:0;
}


.calculate-part{
	@include list-unstyled;
	margin:0 0 0 15px;
	padding:0;
	font-family:$font-family-custom;
	
	li{
		@include clearfix();
		padding:10px 10px 10px 30px;
		border-bottom:1px solid lighten($blue, 46%);
		display:block;
		color:$white;
		font-size:27px;
		font-weight:300;
		
		&:last-child{
			border-bottom:none;
		}
		
		.cal-left-text{
			float:left;
			width:60%;
		}
		.cal-right-text{
			width:40%;
			float:left;
		}
		
		strong{
			font-weight:400;
		}
	}
}

.popup-right-pan{
	width:54%;
	float:left;
	padding:20px 15px;
	
	.green-button{
		margin-bottom:30px;
	}
}

.payment-secure-part{
	@include clearfix();
	margin:0 0 40px 14px;
	font-family:$font-family-custom;
}

.secure-label{
	font-size:14px;
	color:lighten($black, 60%);
	margin:0 10px 0 0;
	float:left;
}
.e-transactions-img{
	float:left;
}
.pay-card-img{
	float:right;
	margin-top:2px;
}

.pay-radio-label{
	display:block;
	font-size:19px;
	color:$blue;
	text-align:right;
}

.pay-month-select{
	display:table-cell;
	width:70px;
}
.pay-year-select{
	display:table-cell;
	width:85px;
}
.pay-select-divider{
    display: table-cell;
    padding: 0 8px;
    white-space: nowrap;
}

.bottom-terms-part{
	font-size:9px;
	color:lighten($black, 38%);
	font-family:$primary-font;
	text-align:center;
}

.subscription-text-pan{
	@include clearfix();
	margin:0 0 0 15px;
	
	.price-text{
		margin-right:20px;
		float:right;
	}
}
.popup-sub-heading{
	font-size:18px;
	font-weight:700;
	margin:0 0 22px;
}

/************Subscription cancellation**************/
.modal-panel-02{
	max-width:780px;
	width:100%;
}

.subscription-popup-left-pan{
	width:178px;
	position:absolute;
	left:1px;
	top:1px;
	bottom:1px;
	background: #0095ad;
	background: -moz-linear-gradient(top,  #0095ad 0%, #00435d 100%);
	background: -webkit-linear-gradient(top,  #0095ad 0%,#00435d 100%);
	background: linear-gradient(to bottom,  #0095ad 0%,#00435d 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0095ad', endColorstr='#00435d',GradientType=0 );
}
.info-icon03{
	display:block;
	position:absolute;
	width:72px;
	height:72px;
	top:50%;
	left:50%;
	margin:-36px 0 0 -36px;
	background:url(#{$imageUrl}/sprite.png) no-repeat 0 -168px;
}
.subscription-popup-right-pan{
	margin:0 0 0 178px;
	padding:35px 10px 40px;
	color:$blue;
	font-family:$font-family-custom;
	text-align:center;
	
	h2{
		font-size:23px;
		font-weight:400;
		margin:0 0 30px;
	}
}

.popup-no-link{
	display:inline-block;
	font-size:29px;
	font-weight:300;
	color:$blue;
	position:relative;
	padding:0 0 0 40px;
	margin:0 30px;
	@include transition(all .2s ease-in-out);
	
	&:after{
		@include after();
		width:32px;
		height:23px;
		left:0;
		top:8px;
		background-position:-99px -178px
	}
	
	&:hover,
	&:focus{
		color:darken($blue, 15%);
		text-decoration:none;
	}
}

.popup-yes-link{
	@extend .popup-no-link;
	padding-left:50px;
	
	&:after{
		background-position: -95px -218px;
	}
}

/*******Put Offer popup********/
.tick-icon{
	display:block;
	position:absolute;
	width:70px;
	height:70px;
	top:50%;
	left:50%;
	margin:-35px 0 0 -35px;
	background:url(#{$imageUrl}/sprite.png) no-repeat -270px -45px;
}
.put-offer-popup-right{
	h2{
		margin:20px 0;
		span{
			display:block;
		}
	}
}





@media (min-width: $screen-sm-max) {
	.modal-dialog{
		margin:0 auto;
	}
}

@media (max-width: $screen-md-max) {
.modal-panel {
    width: 970px;
}
.popup-left-pan{
	padding:30px 20px 20px;
	
	.dropdown{
		margin-left:0;
	}
	
	p{
		margin-bottom:25px;
	}
}
.popup-heading02{
	margin-left:0;
	font-size:22px;
}

.calculate-part{
	margin-left:0;
}
.payment-secure-part{
	margin:0 0 25px 15px;
}

.pay-radio-label{
	font-size:16px;
}
.radio-box{
	&.pay-radio{
		margin:4px 18px 25px 0;
		
		label{
			font-size:18px;
			
			&:after{
				top:5px;
			}
			&:before{
				top:9px;
			}
		}
	}
}

.subscription-text-pan{
	margin-left:0;
}


}

@media (max-width: $screen-sm-max) {
.modal-panel {
    margin: 20px;
    width: auto;
}
.popup-left-pan {
    float: none;
    width: 100%;
	padding:15px;
	min-height:inherit;
	
	p{
		margin-bottom:20px;
	}
}
.popup-bg-heading{
	font-size:16px;
	margin-bottom:18px;
}
.popup-heading02 {
    font-size: 20px;
    margin: 0 0 18px;
}
.credit-drop-link{
	padding:14px 65px;
	margin-bottom:25px;
	&:after{
		right:22px;
	}
}

.drop-text{
	font-size:37px;
	margin-right:5px;
}
.drop-text01,
.drop-text02{
	font-size:20px;
}

.calculate-part{
	li{
		padding:6px 10px 6px 20px;
		font-size:22px;
	}
}
.popup-right-pan {
    float: none;
    width: 100%;
}

.popup-sub-heading{
	margin-bottom:17px;
}

.popup-sub-heading{
	font-size:15px;
}

/*****Subscription cancellation******/
.modal-panel-02{
	width:auto;
	margin:20px;
}
.subscription-popup-right-pan{
	h2{
		font-size:20px;
	}
}
.popup-no-link{
	font-size:26px;
	
	&:after{
		top:6px;
	}
}
	
}

@media (max-width: $screen-xs-max) {
.popup-bg-heading {
    font-size: 15px;
    line-height: 1.4;
    margin-bottom: 18px;
    padding: 5px 10px;
	margin:0 0 12px;
	
	&:after{
		display:none;
	}
}

.popup-left-pan{
	p{
		font-size:15px;
	}
}

.popup-heading02 {
    font-size: 18px;
    margin: 0 0 16px;
}
.credit-drop-link {
    margin-bottom: 15px;
    padding: 12px 35px 12px 44px;
	
	&:before{
		background-position: -160px 0;
		background-size: 410px auto;
		width:27px;
		height:24px;
		margin-top:-12px;
	}
	&:after{
		background-position: -121px 0;
		background-size: 410px auto;
		width:24px;
		height:12px;
		margin-top:-6px;
		right:12px;
	}
}
.drop-text {
    font-size: 25px;
    margin-right: 2px;
}
.drop-text01{
	margin-right:2px;
}
.drop-text01, 
.drop-text02 {
    font-size: 16px;
}


.credit-dropdown{
	li{
		a{
			padding:5px 5px 5px 44px;
			.drop-text {
				font-size: 26px;
			}
			.drop-text01,
			.drop-text02 {
				font-size: 15px;
			}
		}
	}
}
.calculate-part{
	li{
		font-size: 18px;
		padding: 5px 10px;
	}
}
.payment-secure-part {
    margin: 0 0 20px;
}
.secure-label{
	font-size:13px;
}
.pay-card-img {
    display: block;
    float: none;
    margin: 10px auto 0;
}
.pay-radio-label{
	text-align:left;
}

.radio-box{
	&.pay-radio {
		margin:5px 15px 20px 0;
		label {
			font-size: 16px;
			
			&:after{
				top:3px;
			}
			&:before{
				top:7px;
			}
		}	
	}
}

.popup-sub-heading{
	font-size:16px;
}
.popup-left-pan{
	font-size:15px;
}

/*****Subscription cancellation******/
.subscription-popup-left-pan {
    bottom: inherit;
    height: 80px;
    left: inherit;
    margin: 1px 1px 16px;
    position: relative;
    top: inherit;
    width: auto;
}
.info-icon03 {
    background-position: 0 -100px;
    background-size: 323px auto;
    height: 44px;
    margin: -22px 0 0 -22px;
    width: 44px;
}
.subscription-popup-right-pan{
    margin: 0;
    padding: 0 10px 15px;
	
	h2{
		font-size:18px;
		margin-bottom:15px;
	}
}
.popup-no-link{
	margin:0 12px;
	font-size:22px;
	padding-left:35px;
	&:after{
		top:4px;
	}
}
.popup-yes-link {
    padding-left: 44px;
}
/*******Put Offer popup******/
.tick-icon {
    background-position: -160px -26px;
    background-size: 323px auto;
    height: 44px;
    margin: -22px 0 0 -22px;
    width: 44px;
}


}

