//
// Buyesrs thankyou page
// --------------------------------------------------
/************Buyesrs thankyou page***************/
.credit-thanks-heading{
	font-family:$font-family-custom;
	font-size:26px;
	color:$blue;
	text-align:center;
	margin:0 0 40px;
	padding-top:20px;
	font-weight:400;
	
	.like-icon{
		display:inline-block;
		margin:0 15px 0 0;
	}
}

.thank-you-message{
	font-family:$font-family-custom;
	font-size:22px;
	font-weight:300;
	color:$blue;
	margin:0 0 65px;
	
	span{
		display:block;
		font-weight:400;
		 margin-top:4px;
	}
	
	strong{
		color:$red;
	}
}


/**Buyesrs thankyou End**/


