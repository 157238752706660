//
// Your property for sale Confirmation
// --------------------------------------------------
/************Your property for sale Confirmation page***************/
.confirmation-container{
	margin:30px 0 0 0;
	min-height:300px;
	text-align:center;
	
	p{
		margin-bottom:55px;
	}
}

.confirmation-heading{
	display:block;
	font-size:22px;
	color:$black;
	margin:0 0 18px;
	
	strong{
		color:$red;
	}
	mark{
		background:none;
		color:$red;
	}
}


/**Your property for sale Confirmation page End**/


