//
// Mixins
// --------------------------------------------------

@mixin bgColor($color) {
  background-color: $color;
  @include transition(all .3s ease-in-out);
  
  &:hover,
  &:focus{
	 background-color: darken($color, 10%);
  }
}


@mixin after(){
	content:"";
	position:absolute;
	background-image:url(#{$imageUrl}/sprite.png);
	background-repeat:no-repeat;		
}










