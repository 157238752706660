//
// Right Slide
// --------------------------------------------------
/*************Right Slide Part***********/
.right-slide-open{
	.right-slide-panel{
		visibility:visible;
	}
	.right-inner-part{
		-webkit-transform: translate(0, 0); 
		-ms-transform: translate(0, 0); 
		-o-transform: translate(0, 0); 
		transform: translate(0, 0);
	}
	.menu-social,
	.close-right-pan{
		opacity: 1;
	}
	.menu-social-part{
		opacity: 1;
		&:after{
			opacity: 1;
		}
	}
	.menu-social{
		opacity: 1;
	}
}
.right-slide-panel{
	position:fixed;
	right:0;
	top:0;
	height:100%;
	padding:0;
	width:450px;
	overflow:hidden;
	visibility:hidden;
	z-index:999;
	
	.connexion{
		display:inline-block;
		padding:7px 18px 7px 36px;
		margin:15px 0 24px 15px;
		font-size:15px;
		font-family:$font-family-custom;
		text-transform:uppercase;
		border-color:$white;
		color:$white;
		background:darken($blue, 4%);
		text-decoration:none;
		border:2px solid #ffebc2;
		position:relative;
		@include transition(all .2s ease-in-out);
		
		&:after{
			@include after();
			width:12px;
			height:12px;
			left:12px;
			top:11px;
			background-position:-270px 0;
		}
		
		
		&:hover,
		&.active{
			border-color:$white;
			color:$white;
			background:darken($blue, 4%);
		}
	}
	
	.after-login-menu{
		display:block;
		padding:0 16px 0 0;
		margin:38px 10px 22px 15px;
		font-size:12px;
		color:$white;
		position:relative;
		color:$orange;
		font-family:$primary-font;
		text-transform:none;
		overflow:hidden;
		white-space:nowrap;
		text-overflow: ellipsis;
		
		&:after{
			@include after();
			width:9px;
			height:5px;
			background-position:-40px -45px;
			right:0;
			top:6px;
		}
		&:hover,
		&:active{
			color:$orange;
			text-decoration:none;
		}
	}

}
.close-right-pan{
	width:60px;
	height:60px;
	@include bgColor($black);
	display:block;
	font-size:0;
	text-indent:-999999px;
	position:relative;
	z-index:500;
	background-image:url(#{$imageUrl}/sprite.png);
	background-repeat:no-repeat;	
	background-position:-81px -107px;
	opacity:0;
	@include transition(all .3s ease-in-out);
}
.menu-social-part{
	width:60px;
	height:100%;
	position:absolute;
	left:0; 
	top:0;
	display:block;
	opacity:0;
	z-index:500;
	@include transition(all .3s ease-in-out);
	&:after{
		width:100%;
		height:100%;
		content:"";
		position:absolute;
		left:0;
		top:0;
		opacity:0;
		@include transition(all .3s ease-in-out);
		background:lighten($black, 87%) url(#{$imageUrl}/login-social-bg.png) repeat;
	}
	
	
}
.menu-social{
	margin:0;
	@include list-unstyled;
	position:relative;
	z-index:500;
	@include transition(all .3s ease-in-out);
	opacity:0;
	
	li{
		display:block;
		margin:0;
		a{
			display:block;
			width:60px;
			height:60px;
			@include transition(all .2s ease-in-out);
			font-size:0;
			text-indent:-999999px;
			@include bgColor(#3664a2);
			position:relative;
			
			&:after{
				@include after();
				width:22px;
				height:14px;
				margin:-7px 0 0 -11px;
				top:50%;
				left:50%;
				background-position:-426px -103px;
			}

		}
		&.linkedin{
			a{
				@include bgColor(#0374b3);
				&:after{
					background-position:-455px -103px;
				}
			}
		}
		&.twitter{
			a{
				@include bgColor(#55acee);
				&:after{
					background-position:-484px -103px;
				}
			}
		}
		&.google-plus{
			a{
				@include bgColor(#dd4b39);
				&:after{
					background-position:-516px -103px;
				}
			}
		}
	}
}

.forgot-pwd-link{
	text-align:right; 
	display:block; 
	margin:-24px 30px 28px 0;
	
	a{
		text-decoration:underline;
		font-size:11px;
		color:rgba($white, .54);
		
		&:hover{
			text-decoration:none;
		}
	}
}

.navigation-overlay{
	position:fixed;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background:rgba($white,0);
	z-index:900;
}

//Main menu
.right-inner-part{
    background-color:lighten($blue, 10%);
    height: 100%;
    position: absolute;
    left:60px;
	right:0;
	@include transition(all .4s ease-in-out);
	-webkit-transform: translate(-1500px, 0); 
	-ms-transform: translate(-1500px, 0); 
	-o-transform: translate(-1500px, 0); 
	transform: translate(-1500px, 0);
}
.main-menu{
	margin:0;
	padding:0;
	@include list-unstyled;
	li{
		display:block;
		margin-bottom:1px;
		
		a{
			text-decoration:none;
			color:$white;
			font-family:$font-family-custom;
			font-weight:300;
			font-size:25px;
			padding:3px 15px;
			display:block;
			@include transition(all .2s ease-in-out);
		}
		
		&:hover,
		&.active{
			a{
				background-color:$blue;
				color:$orange;
				font-weight:400;
			}
		}
	}
}

/**Right Slide Part End**/


