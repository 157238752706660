//
// Global style
// --------------------------------------------------

/**Global Style**/
body {
  background: $white;
  font-size: ceil(($font-size-base * 1.14));
  font-family: $primary-font;
  line-height: $line-height-large;
  color:$textColor;
}

h1, .h1, 
h2, .h2, 
h3, .h3, 
h4, .h4, 
h5, .h5, 
h6, .h6{
	line-height:1.2;
} 

hr{
	border-color:lighten($black, 75%);
	margin-left:10px;
	margin-right:10px;
}

p{
	margin-bottom:20px;
}

a:focus{
	outline:none;
	color:inherit;
}

img{
	max-width:100%;
}

.body-link01{
	text-decoration:underline;
	color:$textColor;
	
	&:hover{
		text-decoration:none;
	}
}

a:focus{
	text-decoration:inherit;
}

.noTopMargin{
	margin-top:0 !important;
}

/**Global End**/


