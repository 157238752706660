//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination-pan{
	text-align:center;
	margin:0 0 50px;
}
.pagination {
	margin:0;
	> li{
		>a,
		> span{
			color:lighten($black, 40%);	
			font-size:14px;
		}
	}	
}

@media (max-width: $screen-sm-max) {
	.pagination-pan{
		margin-bottom:35px;
	}
}

@media (max-width: $screen-xs-max) {
	.pagination-pan{
		margin-bottom:30px;
	}
	.pagination {
	margin:0;
	> li{
		>a,
		> span{
			font-size:13px;
		}
	}	
}
}

