//
// Your property for sale
// --------------------------------------------------
/************Your property for sale page***************/
.heading02{
	font-size:17px;
	color:$red;
	border-bottom:1px dotted $red;
	padding-bottom:3px;
	font-weight:normal;
	margin:0 0 40px 65px;
}
.checkbox-heading{
	display:block;
	color:$red;
	font-size:14px;
	margin:8px 0 34px;
}
.checkbox-heading02{
	font-size:17px;
	color:$black;
	text-align:right;
	margin:0 0 15px;
}
.checkbox-list{
	@include list-unstyled;
	margin:0 0 26px;
	padding:0;
	li{
		display:block;
		.check-box.checkbox-right{
			margin:0 0 10px;
		}
	}
}



/**Your property for sale page End**/


