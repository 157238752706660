//
// Dashboard page
// --------------------------------------------------
/************Buyer 2 page page***************/
.subscribe-pan{
	font-family:$font-family-custom;
	font-size:15px;
	color:$white;
	text-align:center;
	background-color:$red;
	padding:4px 0;
	margin:0;
	
	a{
		font-weight:700;
		text-decoration:underline;
		color:$white;
		
		&:hover{
			text-decoration:none;
		}
	}
}
.buyer-heading{
	margin-top:50px;
}
.worning-icon{
	width:13px;
	height:11px;
	margin:0 10px 0 0;
	display:inline-block;
	background:url(#{$imageUrl}/sprite.png) no-repeat -200px -125px;
}

.search-pan{
	@include clearfix();
	padding:8px 0;
	background-color:lighten($black, 95%);
	margin:0 0 50px;
	font-family:$font-family-custom;
	color:$blue;
	font-weight:300;
	
	.form-control{
		font-family:$font-family-custom;
	}
	
	h2{
		font-size:25px;
		margin:2px 0 0;
		width:23%;
		float:left;
		font-weight:300;
		text-align:right;
		padding-right:20px;
	}
	
	.search-input-form{
		margin:0 12px 0 0;
		width:10%;
		float:left;
	}
	.form-control{
		background-color:lighten($blue, 73%);
		color:lighten($black, 30%);
		@include placeholder(lighten($black, 30%));
		height:35px;
		border:1px solid lighten($black, 68%);
		font-size:15px;
	}
	
	label{
		font-weight:300;
		color:$blue;
		font-size:15px;
		margin:4px 20px 0 12px;
		float:left;
		font-family:$font-family-custom;
	}
	
	.search-select-form{
		width:16%;
		float:left;
		margin:0 12px 0 0;
	}
}
.search-ok-btn{
	font-size:15px;
	color:$white;
	@include bgColor($blue);
	width:35px;
	height:35px;
	border:none;
	text-transform:uppercase;
	font-weight:400;
	
	&:hover,
	&:focus{
		color:$white;
		outline:none;
	}
}

.search-select-form{
	.bootstrap-select{
		.dropdown-toggle{
			border:none;
			background-color:lighten($blue, 73%);
			height:33px;
			font-weight:300;
			font-size:15px;
			
			.bs-caret{
				width:35px;
				height:33px;
				position:absolute;
				right:0;
				top:0;
				background-color:$blue;
				
				.caret{
					width:9px;
					height:5px;
					left:50%;
					margin:-2px 0 0 -4px;
					background:url(#{$imageUrl}/sprite.png) no-repeat -40px -45px;
				}
			}
		}
	}
	.dropdown-menu{
		border:1px solid lighten($black, 68%);
		background-color:lighten($blue, 73%);
		
		ul{
			li{
				a{
					font-weight:300;
					font-size:14px;
				}
			}
		}
	}
}

.selesroom-box{
	border:1px solid lighten($black, 85%);
	padding:24px 18px 18px;
	background-color:$white;
	margin:0 0 45px;
	color:lighten($black, 20%);
	font-family:$font-family-custom;
	
	&.gray-tag{
		.salesroom-tag{
			background-color:#cac1b0;
		}
	}
	&.light-blue-tag{
		.salesroom-tag{
			background-color:lighten($blue, 65%);
		}
	}
	
	h3{
		font-weight:700;
		color:$blue;
		font-size:20px;
		padding-bottom:10px;
		margin-bottom:12px;
		border-bottom:1px dotted $blue;
		text-align:center;
	}
	
	p{
		font-size:12px;
		margin:0 0 14px;
		font-family:$primary-font;
	}
}
.salesroom-tag-container{
	margin-top:-37px;
	text-align:center;
}
.salesroom-tag{
	display:inline-block;
	text-transform:uppercase;
	line-height:24px;
	padding:0 18px;
	position:relative;
	background-color:$blue;
	color:$white;
}

.selesroom-tag-list{
	@include list-unstyled;
	margin:0 0 10px;
	padding:0;
	
	li{
		display:block;
		float:left;
		margin:0 8px 8px 0;
		font-size:15px;
		color:$black;
		
		span{
			color:$blue;
		}
	}
}

.learn-more-link{
	font-size:13px;
	color:$blue;
	display:inline-block;
	text-transform:uppercase;
	padding:0 0 0 25px;
	position:relative;
	margin:0 0 16px;
	
	&:after{
		@include after();
		width:15px;
		height:15px;
		background-position:-370px -45px;
		top:2px;
		left:0;
	}
}

.ref-no-text{
	font-size:12px;
	display:block;
	color:lighten($black, 70%);
}

.price-circle-pan{
	width:148px;
	height:148px;
	margin:0 auto 5px;
	text-align:center;
	font-size:12px;
	color:$blue;
	padding:34px 12px 15px;
	background:url(#{$imageUrl}/price-circle.jpg) no-repeat 0 0 / cover;
	
	span{
		display:block;
		margin-bottom:2px;
	}
	
	big{
		color:$red;
		letter-spacing:-2px;
		display:block;
		line-height:1;
		margin:0 0 2px;
		font-size:43px;
		
		sup{
			font-size:24px;
			font-weight:300;
			margin-left:-4px;
			top:-16px;
		}
	}
	
	small{
		font-size:11px;
		display:block;
	}
	
}

.timer-heading{
	
	font-size:16px;
	display:block;
	margin:0 0 2px;
	color:$black;
	text-align:center;
}
.countdown{
	@include list-unstyled;
	margin:0 0 12px;
	padding:0;
	text-align:center;
	
	li{
		display:inline-block;
		font-size:21px;
		font-weight:700;
		color:$red;
	}
}

.heading09{
	font-size:19px;
	color:$blue;
	font-family:$font-family-custom;
	font-weight:400;
	margin:0 0 26px;
}

/**Buyer 2 page End**/


