//
// Mandats information
// --------------------------------------------------
/************Mandats information page***************/
.form-box-pan{
	margin-bottom:60px;
}

.privacy-checkbox{
	margin:35px 0 40px;
}

.captcha-container{
	max-width:255px; 
	margin:0 auto 58px;
}

.terms-text{
	display:block;
	text-align:center;
	margin:0 0 25px;
	color:$black;
	a{
		text-decoration:underline;
		color:$black;
		
		&:hover{
			text-decoration:none;
		}
	}
}

.info-icon{
	display:inline-block;
	position:relative;
	width:10px;
	height:10px;
	top:-12px;
	left:-4px;
	margin-right:-12px;
	background:url(#{$imageUrl}/sprite.png) no-repeat -80px -45px;
}

.enter-date-text{
	display:block;
	font-size:11px;
	color:$red;
	margin-top:10px;
}

//Question part
.left-question-pan{
	background-color:$red;
	font-size:13px;
	color:$white;
	text-transform:uppercase;
	padding:8px 25px 8px 45px;
	width:100%;
	max-width:270px;
	position:absolute;
	margin-top:-25px;
	left:0;
	z-index:300;
	font-family:$font-family-custom;
	@include transition(all .2s ease-in-out);
	
	&.ques-fixed{
		position:fixed;
		top:0;
		margin:0;
	}
	
	&:after{
		content:"";
		width:35px;
		height:100%;
		position:absolute;
		left:4px;
		top:0;
		background:url(#{$imageUrl}/mobile-icon.png) no-repeat right top / auto 100%;
	}
	
	.left-question-link{
		color:$white;
		
		&:hover{
			text-decoration:none;
		}
	}
	
	.question-close{
		font-family:$primary-font;
		color:$white;
		padding:1px 6px;
		right:4px;
		top:3px;
		display:inline-block;
		position:absolute;
		@include transition(all .2s ease-in-out);
		
		&:hover{
			text-decoration:none;
			background-color:$black;
		}
	}
}



/**Mandats information page End**/


