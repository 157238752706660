//
// Buyesrs landing page
// --------------------------------------------------
/************Buyesrs landing page page***************/
.dashboard-banner-pan{
	background-color:$blue;
	color:$white;
	margin:0;
	padding:35px 0 10px;
	position:relative;
	overflow:hidden;
		
	&:before{
		content:"";
		position:absolute;
		left:0; 
		bottom:0;
		width:100%;
		height:46px;
		background:url(#{$imageUrl}/banner-bottom-bg.png) repeat;
	}
	
	&:after{
		content:"";
		position:absolute;
		right:-6px;
		bottom:4px;
		width:218px;
		height:123px;
		background:url(#{$imageUrl}/house-image.png) no-repeat;
		background-size: 100% auto;
	}
}

.dashboard-user-pan{
	margin:15px 0;
	position:relative;
	padding:6px 0 0 120px;
	min-height:90px;
}
.dashboard-user-image{
	display:block;
	width:91px;
	height:91px;
	position:absolute;
	left:0;
	top:0;
	border-radius:50%;
	img{
		border-radius:50%;
		display:block;
	}
}
.dashboard-user-name{
	font-family:$font-family-custom;
	font-weight:300;
	font-size:43px;
	margin:0;
	
	a{
		text-decoration:none;
		color:$white;
		
		&:hover{
			text-decoration:underline;
		}
	}
	
	small{
		font-size:15px;
		font-family:$primary-font;
		display:block;
		color:$white;
		margin-top:4px;
	}
}

.banner-right-box{
	@include list-unstyled;
	@include clearfix;
	margin:0 10px 0 -10px;
	position:0;
	position:relative;
	z-index:500;
	text-align:right;
	li{
		display:inline-block;
		padding:0 7px;
		margin:0 -2px 15px;
		vertical-align:top;
		width:33.3333%;
		text-align:left;
		
		.banner-box-inner{
			border:1px  dashed $white;
			color:$white;
			font-size:14px;
			padding:10px 10px 68px;
			border-radius:3px;
			position:relative;
			min-height:120px;
			background:$blue;
			
			big{
				color:rgba($white, .6);
				font-size:58px;
				font-family:$font-family-custom;
				font-weight:300;
				position:absolute;
				right:20px;
				bottom:10px;
				line-height:1;
			}
		}
		
		&.red-text{
			.banner-box-inner{
				big{
					color:$red;
				}
			}
		}
	}
}


.paragraph03{
	font-size:18px;
	color:lighten($black, 30%);
	margin:0 0 36px;
}

.buyers-left-pan{
	padding:42px 30px 10px;
	color:$white;
	margin:0 -30px 100px 0;
	font-family:$font-family-custom;
	background:url(#{$imageUrl}/discovery-bg.jpg) no-repeat right center / cover;
	
	h3{
		font-size:34px;
		font-weight:300;
		text-transform:uppercase;
		margin:0 0 24px;
		
		strong{
			font-weight:700;
		}
	}
	p{
		font-size:16px;
		line-height:1.6;
		margin-bottom:30px;
	}
	
	.paragraph04{
		font-weight:700;
		font-size:19px;
		margin:0 0 24px;
		line-height:1.4;
	}
	
	.btn{
		font-size: 20px;
		font-weight: 700;
		padding: 8px 40px;
		position: relative;
		top: 30px;
	}
	
}

.price-text{
	display:inline-block;
	font-size:16px;
	
	big{
		font-size:105px;
		color:$orange;
		font-weight:300;
		line-height:1;
		position:relative;
		letter-spacing:-3px;
		
		sup{
			font-size: 33px;
			margin-left: -15px;
			top: -60px;
			position:relative;
			span{
				font-size: 25px;
				position: absolute;
				top: 26px;
			}
		}
	}
	.price-text-2{
		font-size: 26px;
		font-weight: 400;
		margin-left: -45px;
		position: relative;
		top: -30px;
	}
	.price-text-3 {
		margin: 0 0 0 -50px;
		position: relative;
		top: -5px;
		white-space: nowrap;
	}

}

.buyers-right-part{
	font-family:$font-family-custom;
	margin:0 0 100px;
	
	ul{
		margin:0 0 20px;
		padding:0;
		@include list-unstyled;
		
		li{
			display:block;
			font-size:17px;
			color:$blue;
			border-bottom:1px solid lighten($black,90%);
			padding:16px 0 16px 90px;
			position:relative;
			
			&:last-child{
				border-bottom:none;
			}
			
			strong{
				font-weight:700;
			}
			
			&:before{
				@include after();
				width:27px;
				height:20px;
				background-position:-150px -125px;
				left:25px;
				top:50%;
				margin-top:-10px;
			}
		}
	}
}

.buyers-heading02{
	font-size:24px;
	color:lighten($black, 58%);
	text-transform:uppercase;
	letter-spacing:2px;
	margin:35px 0 20px 58px;
	position:relative;
	font-weight:300;
}
.arrow-icon{
	width:55px;
	height:40px;
	margin:0 0 18px -18px;
	display:block;
	background:url(#{$imageUrl}/sprite.png) no-repeat -430px -46px;
}

.skip-link{
	float:right;
	margin:0 28px 0 0;
	text-decoration:underline;
	color:lighten($black, 20%);
	font-size:15px;
	text-transform:uppercase;
	
	&:hover{
		text-decoration:none;
	}
}


/**Buyesrs landing page End**/


