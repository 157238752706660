//
// Buyesrs Invoice page
// --------------------------------------------------
/************Buyesrs Invoice page***************/
.invoice-download-link{
	color:$black;
	display:inline-block;
	position:relative;
	text-decoration:none;
	padding:0 38px 0 0;
	
	&:hover,
	&:focus{
		color:$black;
		text-decoration:underline;
		outline:none;
	}
	
	&:after{
		@include after();
		width:16px;
		height:20px;
		right:0;
		top:0;
		background-position:-228px -89px;
	}
}

.cancel-subscription-link{
	display:inline-block;
	color:$red;
	font-family:$font-family-custom;
	font-size:13px;
	text-decoration:none;
	text-transform:uppercase
	
	&:hover,
	&:focus{
		color:$red;
		outline:none;
		text-decoration:underline;
	}
}

.invo-subs-text{
	display:block;
	margin:0 0 10px;
	font-size:14px;
	font-family:$font-family-custom;
	font-weight:700;
	color:$red;
	text-transform:uppercase;
	
	a{
		text-decoration:none;
		color:#029002;
		&:hover,
		&:focus{
			color:#029002;
			outline:none;
			text-decoration:underline;
		}
	}
}

.worning-red-icon{
	width:18px;
	height:16px;
	margin:0 8px 0 10px;
	display:inline-block;
	position:relative;
	top:2px;
	background:url(#{$imageUrl}/sprite.png) no-repeat -229px -129px;
}

/**Buyesrs Invoice End**/


