//
// Seller Account 2
// --------------------------------------------------
/************Seller Account 2 page***************/
.heading04{
	color:$black;
	font-size:30px;
	font-family:$font-family-custom;
	margin:0 0 28px;
	font-weight:400;
}
.back-page-link{
	@include bgColor($red);
	display:block;
	width:280px;
	height:100%;
	position:absolute;
	right:0;
	top:0;
	padding:10px 10px 8px 30px;
	text-decoration:none;
	font-size:13px;
	color:$white;
	text-transform:uppercase;
	
	&:hover,
	&:focus{
		color:$white;
	}
	
	span{
		position:absolute;
		left:30px;
		bottom:8px;
		
		&:after{
			@include after();
			width:15px;
			height:15px;
			background-position:0 -130px;
			left:-21px;
			top:50%;
			margin-top:-7.5px;
		}
		
	}
}

.seller-top-menu{
	@include list-unstyled;
	margin:0 -9px;
	padding:0;
	
	li{
		width:20%;
		float:left;
		padding:0 9px;
		margin-bottom:22px;
		
		a{
			display:block;
			border:1px solid lighten($black, 75%);
			padding:32px 20px 5px 15px;
			min-height:88px;
			color:$black;
			font-size:15px;
			font-weight:bold;
			position:relative;
			@include transition(all .2s ease-in-out);
			
			span{
				display:block;
			}
			
			&:after{
				@include after();
				width:13px;
				height:13px;
				top:9px;
				right:15px;
				background-position:-50px -85px;
			}
		}
		
		&:hover,
		&.active{
			a{
				text-decoration:none;
				color:$red;
				background:lighten($black, 98%);
				
				&:after{
					background-position:-100px -85px;
				}
			}
		}

	}
}

.seller-info-tag{
	@include list-unstyled;
	@include clearfix();
	margin:0 0 55px;
	padding:16px 0 11px;
	border-top:1px dotted lighten($black, 30%);
	border-bottom:1px dotted lighten($black, 30%);
	
	li{
		display:block;
		float:left;
		padding:0;
		margin-bottom:4px;
		color:$black;
		position:relative;
		font-size:14px;
		strong{
			text-decoration:underline;
			padding-right:9px;
		}
		&:first-child{
			a{
				padding-left:0;
			}
		}
		&:last-child{
			a{
				padding-right:0;
			}
			&:after{
				display:none;
			}
		}
		&:after{
			content:"I";
		}
		a{
			text-decoration:none;
			color:$black;
			padding:0 9px;
			
			&:hover{
				text-decoration:none;
			}
		}
	}
}

.seller-form-container{
	margin-bottom:65px;
}


/**Seller Account 2 page End**/


