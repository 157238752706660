//
// Won auction page
// --------------------------------------------------
/************Won auction page***************/
.won-auction-top-pan{
	background-color:$green;
	width:240px;
	height:240px;
	padding:15px 10px;
	margin:0 auto 20px;
	color:$white;
	font-family:$font-family-custom;
	text-align:center;
	font-size:18px;
	border-radius:50%;
	
	span{
		font-weight:700;
		margin:0 0 5px;
		display:block;
		letter-spacing:-0.02em;
	}
	
	big{
		display:block;
		font-size:75px;
		letter-spacing:-5px;
		margin-bottom:5px;
		line-height:.9;
		
		sup{
			font-size:27px;
			font-weight:300;
			top:-35px;
		}
	}
	small{
		display:block;
		font-size:17px;
	}
}

.like-icon02{
	display:block;
	margin:0 auto 12px;
	width:35px;
	height:39px;
	background:url(#{$imageUrl}/sprite.png) no-repeat -381px -144px;
}

.won-auction-border{
	border-color:$blue;
	border-style:dotted;
	margin:12px 0 22px;
}

.seller-info-pan{
	border:1px solid $green;
	padding:1px;
	font-family:$font-family-custom;
	margin-bottom:4px;
	
	h4{
		font-size:18px;
		color:$blue;
		text-transform:uppercase;
		text-align:center;
		padding:20px 10px;
		margin:0 0 20px;
		position:relative;
		&:after{
			@include after();
			width:100%;
			height:100%;
			left:0;
			top:0;
			background:url(#{$imageUrl}/banner-bottom-bg.png) repeat;
			opacity:.35;
		}
		
	}
}

.seller-info-inner-text{
	padding:0 25px 15px;
}

.seller-info-text{
	font-size:16px;
	display:block;
	margin:0 0 7px;
	
	a{
		color:$blue;
		text-decoration:none;
		
		&:hover{
			text-decoration:underline;
		}
	}
	strong{
		font-weight:700;
		color:$blue;
	}
	
}
.seller-tel-text{
	font-size:14px;
	font-style:italic;
	color:$black;
	margin:0 0 6px;
	display:block;
}

.complete-description-btn{
	@include bgColor($blue);
	display:block;
	width:100%;
	font-size:16px;
	font-weight:700;
	text-transform:uppercase;
	color:$white;
	padding:9px 10px 9px 38px;
	position:relative;
	
	&:after{
		@include after();
		width:11px;
		height:11px;
		background-position:-323px -136px;
		position:absolute;
		left:15px;
		top:15px;
	}
	
	&:hover{
		text-decoration:none;
		color:$white;
	}
}


/**Won auction End**/


