//
// My tenders
// --------------------------------------------------
/************My tenders page***************/
.heading-pan{
	background-color:lighten($black, 95%);
	padding:22px 0 10px;
	margin:0 0 48px;
	position:relative;
	
	&:after{
		content:"";
		position:absolute;
		left:0; 
		top:100%;
		width:100%;
		height:24px;
		opacity:.45;
		background:url(#{$imageUrl}/banner-bottom-bg.png) repeat;
	}
	
	h1{
		font-size:30px;
		margin:0;
		font-weight:300;
		font-family:$font-family-custom;
		color:$blue;
	}
	
}

.heading03{
	font-size:15px;
	color:$black;
	font-weight:bold;
	margin:0 0 26px;
}

.table-wrapper{
	margin:0 0 60px;
	position:relative;
	border:1px solid lighten($black, 86%);
	padding:10px 0 0 0;
}

//Table
.table{
	margin:0;
	padding:0;
	> tbody {
		> tr{
			&:nth-of-type(odd){
				background:none;
			}
			&:nth-of-type(even){
				background-color:lighten($black, 98.5%);
			}
			> th{
				color:$black;
				border-top:none;
				border-right:1px dotted lighten($black, 65%);
				padding:10px 13px;
				vertical-align:middle;
				font-size:15px;
				text-align:left;
				
				&:last-child{
					border:none;
				}
			}
			>td{
				@extend th;
				border-right-style:solid;
				border-right-color:lighten($black, 86%);
				font-size:14px;
				
				strong{
					color:$red;
				}
			}
		}
	}
}

.col-01{
	width:40%;
}
.col-02{
	width:10%;
}
.col-03{
	width:11%;
}
.col-04{
	width:22%;
}
.col-05{
	width:16%;
}

.bottom-info-container{
	margin:0 0 30px;
	
	p{
		font-size:12px;
		color:lighten($black, 20%);
		margin-bottom:12px;
		
		mark{
			background:none;
			color:$red;
			padding:0;
		}
	}
	
	
}

.info-icon-02{
	width:20px;
	height:20px;
	margin:0 0 10px;
	display:block;
	background:url(#{$imageUrl}/sprite.png) no-repeat 0 -85px;
}


/**My tenders page End**/


