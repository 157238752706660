//
// Create your bidding
// --------------------------------------------------
/************Create your bidding page***************/
.bidding-text-container{
	min-height:140px;
	color:lighten($black, 30%);
	p{
		font-size:18px;
	}
}
.create-tender-container{
	margin:0 -15px 70px;
	background-color:$orange;
	padding:0 28px 5px;
	color:$white;
	min-height:215px;
	text-align:center;
	position:relative;
	font-family:$font-family-custom;
	
	&:after{
		@include after();
		width:180px;
		height:86px;
		bottom:0;
		right:0;
		background-image:url(#{$imageUrl}/settings-icon.png);
		
	}
	
	h3{
		font-weight:400;
		margin:0 0 20px;
		font-size:25px;
		text-transform:uppercase;
	}
	p{
		font-size:17px;
		font-weight:300;
	}
	
	&.red-color{
		background-color:$red;
		
		&:after{
			width:90px;
			height:180px;
			background-image:url(#{$imageUrl}/mobile-icon.png);
			
		}
		
		h3{
			font-size:23px;
			margin-bottom:16px;
			
			.tender-chat-link{
				display:block;
				font-weight:700;
				text-decoration:underline;
				color:$white;
				text-transform:none;
				&:hover{
					text-decoration:none;
				}
			}
		}
		
		p{
			strong{
				font-weight:700;
			}
		}
		
		.tender-no{
			background-color:darken($red, 20%);
		}
	}
	
}



.tender-no{
	display:block;
	width:70px;
	height:88px;
	line-height:1.8;
	border:2px solid $white;
	font-weight:300;
	font-size:43px;
	margin:0 auto -20px;
	position:relative;
	top:-42px;
	background-color:darken($orange, 20%);
}

/**Create your bidding page End**/


