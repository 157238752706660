//
// Buyesrs Auction
// --------------------------------------------------
/************Buyesrs Auction page***************/
.table-wrapper{
	&.smaill-table{
		.table{
			> tbody {
				> tr{
					> th{
						padding:8px;
						font-size:14px;
					}
					
					&.completed-text{
						background-color:lighten($black, 90%);
					}
					
					> td{
						font-size:13px;
						
						&.put-offer-link{
							background-color:red;
							text-align:center;
							font-size:11px;
							font-weight:bold;
							color:$white;
							border-bottom:1px solid $white;
							line-height:1.2;
							
							a{
								color:$white;
								text-decoration:none;
								@include transition(all .2s ease-in-out);
								
								&:focus{
									color:$white;
									text-decoration:none;
								}
								&:hover{
									color:$black;
									text-decoration:none;
								}
								
							}
						}
						
						&.completed-text{
							background-color:lighten($black, 90%);
						}
						
						&.offer-win-text{
							@extend .put-offer-link;
							background-color:#96d044;
						}
						&.not-win-text{
							@extend .put-offer-link;
							background-color:lighten($black, 54%);
						}
						
						&.last-col-box{
							border-bottom:none;
						}
						
					}
				}

			}
		}
	}
}


/**Buyesrs Auction End**/


