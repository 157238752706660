//
// The accesses
// --------------------------------------------------
/************The accesses page***************/
.heading-container{
	border-bottom:1px dotted $blue;
	margin:0 0 45px;
	@include clearfix();
}
.heading07{
	font-family:$font-family-custom;
	font-size:20px;
	color:$blue;
	font-weight:400;
	margin:0 0 15px;
	float:left;
}

.chenge-pwd-link{
	font-size:14px;
	color:$red;
	float:right;
	text-decoration:underline;
	margin:5px 0;
	&:hover{
		color:$red;
		text-decoration:none;
	}
}

.edit-profile-container{
	.green-button{
		margin-top:20px;
	}
}


/**The accesses End**/


