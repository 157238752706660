//
// Form
// --------------------------------------------------
.form-control{
	height: 32px;
	color: $black;
	border:none;
	border-bottom:1px solid lighten($black, 77%);
	@include placeholder($black);
	position:relative;
	box-shadow:none;
	border-radius:0;
	padding:2px 12px;
	@include transition(all .3s ease-in-out);
	-webkit-appearance: none;
	font-size: ceil(($font-size-base * 1.14));
	font-family:$primary-font;
	
	&:focus{
		box-shadow:none;
		border-color:$blue;
	} 
}
.form-group{
	margin:0 0 22px 0;
}
.login-form{
	margin:0 0 20px 0;
	
	&.error{
		.form-control{
			background-color:red;
		}
	}
	
	.error-message{
		margin-bottom:18px;
	}
	
	.form-control{
		font-size:25px;
		font-weight:300;
		font-family:$font-family-custom;
		height:42px;
		border:none;
		background-color:$blue;
		color:$white;
		@include placeholder($white);
	}
}

.red-placeholder{
	.form-control{
		@include placeholder($red);
	}
}


select.form-control{
	padding-right:32px;
	padding-left:10px;
	-webkit-appearance: none;
	-moz-appearance: none;
	background-color: white;
    background-position: 94% 12px;
    background-repeat: no-repeat;
    background-image:url(#{$imageUrl}/selectbox-arrow.png);

}
select::-ms-expand {
    display: none;
}
.ie9{
	select.form-control{
		padding-right:5px;
		background-image:none;
	}	
}

label{
	color: $textColor;
	text-align:right;
	display:block;
	font-weight:normal;
	padding:5px 0;
	border-radius:0;
	white-space:normal;
	margin:0;
	font-family:$primary-font;
}

.field-right-text{
	display:block;
	margin:6px 0 16px;
	color:$black;
	font-family:$primary-font;
}

.datepicker-from{
	position:relative;
	.datetimepicker{
		padding-left:42px;
		position:relative;
		z-index:100;
		background:none;
	}
	&:after{
		content:"";
		background:url(#{$imageUrl}/calender-icon.png) no-repeat;
		width:21px;
		height:23px;
		position:absolute;
		left:12px;
		top:3px;
	}
}

.from-to-input{
	display:inline-table;
	span{
		display: table-cell;
		padding: 0 8px;
		white-space: nowrap;
	}
	.form-control{
		display:table-cell;
		
		&.bootstrap-select{
			display:table-cell;
			width:30%;
		}
	}
}

/* checkbox */
.check-box{
  position: relative;
  margin: 0 0 22px;
  padding:0;
  
	&.checkbox-right{
		margin-top:5px;
		label{
			padding:0 24px 0 0;
			text-align:right;
			
			&:after{
				left:auto;
				right:0;
				top:5px;
			}
			&:before{
				left:auto;
				right:3px;
				top:8px;
			}
		}
	}

  label {
	color: $textColor;
	position:relative;
	padding:0 0 0 24px;
	cursor:pointer;
	font-weight:normal;
	margin:0;
	text-align:left;
	
    &:after {
      	content:"";
		width:13px;
		height:13px;
		position:absolute;
		border:2px solid lighten($black, 78%);
		left:0;
		top:4px;
		@include transition(background-color 0.2s linear);
    }
	
	&:before{
		content:"";
		width:7px;
		height:7px;
		position:absolute;
		left:3px;
		top:7px;
		background:$white;
		@include transition(background-color 0.3s linear);
	}
	
  }
  input[type=checkbox] {
    visibility: hidden;
	position:absolute;
	left:-999999px;
	top:-999999px;
	&:checked + label{

	}
    &:checked + label:before{
		 background-color:$blue;
	}  
  } 
}


/* Checkbox end */

.input-file-link{
	font-size:14px;
	padding:3px 38px 2px 0;
	color:$textColor;
	text-decoration:underline;
	display:inline-block;
	position:relative;
	margin-top:5px;
	
	&:after{
		@include after();
		width:25px;
		height:24px;
		right:0;
		top:0;
		background-position:-510px -45px;
	}
	
	&:hover{
		color:$textColor;
		text-decoration:none;
	}
}

.upload-file{
	display:block;
	font-size:12px;
	color:$black;
}

.input-file{
	position:absolute;
	left:-999999px;
	top:-999999px;
}

.price-form{
	position:relative;
	.form-control{
		padding-right:26px;
	}
}
.form-right-text{
	font-size:16px;
	position:absolute;
	right:12px;
	top:6px;
}

//Error
.error-message{
	display:none;
	color:$brand-danger;
	font-size: ceil(($font-size-base * .85));
	font-style:italic;
	text-align:right;
	margin:-22px 0 6px;
	font-weight:bold;
}

//Selectbox
.bootstrap-select{
	&.open{
		.dropdown-toggle{
			background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
			border-color: #003a54;
			box-shadow: none;
		}
	}
	.dropdown-toggle{
		height: 32px;
		color: $black;
		display:block;
		border:none;
		border-bottom:1px solid lighten($black, 77%);
		@include placeholder($black);
		position:relative;
		box-shadow:none;
		border-radius:0;
		padding:2px 32px 2px 12px;
		@include transition(all .3s ease-in-out);
		-webkit-appearance: none;
		font-size: ceil(($font-size-base * 1.14));
		font-weight:400;
		
		.bs-caret{
			.caret{
				border:none;
				width:14px;
				height:8px;
				right:15px;
				margin-top:-4px;
				background-repeat: no-repeat;
				background-image:url(#{$imageUrl}/selectbox-arrow.png);
			}
		}
	}
	
	.btn-default:hover,
	.btn-default:active:hover, .btn-default:active:focus, .btn-default.focus:active, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus, .open > .btn-default.dropdown-toggle:hover, .open > .btn-default.dropdown-toggle:focus, .open > .btn-default.dropdown-toggle.focus,
	.btn-default:focus, .btn-default.focus{
		background:none !important;
		color: $black;
		outline:none !important;
	}
	.dropdown-menu{
		border-radius:0;
		box-shadow:none;
		border-color:1px solid lighten($black, 77%);
		margin:-1px 0 0 0;
		>li{
			margin-bottom:1px;
			&.selected{
				>a{
					background-color:$blue;
					color:$white;
				}
			}
			>a{
				font-size: ceil(($font-size-base * 1.05));
				padding:3px 12px;
				&:hover, 
				&:focus{
					background-color:$blue;
					color:$white;
				}
			}
		}
	}
}

//Payment form
/* Radiobox */
.radio-box{
  position: relative;
  margin: 0 0 22px;
  padding:0;
  
  	&.pay-radio{
		margin:4px 28px 32px 0;
		float:left;
		
		label{
			font-size:20px;
			font-weight:300;
			font-family:$font-family-custom;
			color:$blue;
			padding-left:25px;
			
			&:after{
				top:7px;
			}
			&:before{
				top:11px;
			}
		}
	}
  

  label {
	color: $textColor;
	position:relative;
	padding:0 0 0 24px;
	cursor:pointer;
	font-weight:normal;
	margin:0;
	text-align:left;
	
    &:after {
      	content:"";
		width:17px;
		height:17px;
		position:absolute;
		border:2px solid lighten($black, 78%);
		left:0;
		top:3px;
		@include transition(background-color 0.2s linear);
		border-radius:50%;
    }
	
	&:before{
		content:"";
		width:9px;
		height:9px;
		position:absolute;
		left:4px;
		top:7px;
		background:$white;
		@include transition(background-color 0.3s linear);
		border-radius:50%;
	}
	
  }
  input[type=radio] {
    visibility: hidden;
	position:absolute;
	left:-999999px;
	top:-999999px;
	&:checked + label{

	}
    &:checked + label:before{
		 background-color:$blue;
	}  
  } 
}

/* Radiobox end */

.from-inner-text{
	position:relative;
	>span{
		left: 10px;
		position: absolute;
		top: 4px;
		z-index: 200;
		color:$black;
	}
	
	.form-control{
		padding-left:30px;
	}
}



@media (max-width: $screen-sm-max) {
.form-control,
.check-box label,
.radio-box label,
.bootstrap-select .dropdown-toggle{
	font-size: ceil(($font-size-base * 1.07));
}
.bootstrap-select .dropdown-menu > li > a{
	font-size:$font-size-base;
}

.radio-box{
	label{
		&:after{
			top:1px;
		}
		&:before{
			top:5px;
		}
	}
}

.from-inner-text{
	>span{
		top:7px;
	}
	.form-control{
		padding-left:27px;
	}
}


}

@media (max-width: $screen-xs-max) {
.form-control{
	padding-left:8px;
	padding-right:8px;
}
.form-control,
.check-box label,
.radio-box label{
	font-size: $font-size-base;
}
.bootstrap-select .dropdown-menu > li > a{
	font-size:ceil(($font-size-base * .92));
}
label {
    margin: 0 0 2px;
    padding: 0;
    text-align: left;
}
.bootstrap-select{
	.dropdown-toggle{
		font-size: $font-size-base;
		
		.bs-caret .caret{
			right:10px;
		}
	}
}

select.form-control{
	padding-right:26px;
	padding-left:8px;
	background-position:97% 12px;
}

.input-file-link:after{
	top:3px;
}

.check-box.checkbox-right{
	margin-top:0;
}

.from-to-input{
	width:100%;
	&.input-break{
		display:block;
		span {
			display: block;
			padding: 12px 0 0;
			white-space: normal;
		}
		.form-control{
			display:block;
			
			&.bootstrap-select{
				display:block;
				width:100%;
				
				span{
					padding:0;
				}
			}
		}
	}
}

.check-box{
	label{
		&:after{
			top:3px;
		}
		&:before{
			top:6px;
		}
	}
}

.input-file-link{
	font-size:13px;
}

}

