//
// Inscription Thank you
// --------------------------------------------------
/************Inscription Thank you page***************/
.like-icon{
	display:block;
	margin:0 auto 16px;
	width:27px;
	height:29px;
	background:url(#{$imageUrl}/sprite.png) no-repeat -380px -95px;
}
.heading-border{
	font-size:37px;
	color:$blue;
	font-family:$font-family-custom;
	font-weight:300;
	text-align:center;
	margin:0 0 25px;
	position:relative;
	
	span{
		display:inline-block;
		padding:0 40px;
		background:$white;
		position:relative;
		z-index:200;
	}
	
	&:after{
		content:"";
		width:100%;
		height:1px;
		background-color:lighten($black, 77%);
		position:absolute;
		left:0;
		top:49%;
	}
}

.thankyou-text-pan{
	min-height:210px;
	margin:0 0 20px;
	text-align:center;
	font-size:13px;
}

.paragraph01{
	font-size:16px;
	margin-bottom:28px;
}
.red-bold-link{
	font-weight:bold;
	color:$red;
	text-decoration:none;
	
	&:hover{
		color:$red;
	}
}






/**Inscription Thank you page End**/


