//
// Inscription
// --------------------------------------------------
/************Inscription page***************/
.heading-icon{
	font-size:22px;
	color:$black;
	padding:6px 0 10px 65px;
	font-weight:normal;
	margin:0 0 32px;
	position:relative;
	
	i{
		width:50px;
		height:42px;
		border-right:2px dotted lighten($black, 92%);
		position:absolute;
		left:0;
		top:0;
		
		&:after{
			@include after();
			width:32px;
			height:31px;
			left:0;
			top:5px;
		}
		
		&.user-icon{
			&:after{
				background-position:-360px 0;
			}
		}
		
		&.note-icon{
			&:after{
				background-position:-410px 0;
			}
		}
		
		&.home-icon{
			&:after{
				background-position:-460px 0;
			}
		}
		&.office-icon{
			&:after{
				background-position:-310px 0;
			}
		}
		&.settings-icon{
			&:after{
				background-position:-510px 0 ;
			}
		}
		
	}
}

.privacy-checkbox{
	margin:35px 0 40px;
}

.captcha-container{
	max-width:255px; 
	margin:0 auto 58px;
}

.terms-text{
	display:block;
	text-align:center;
	margin:0 0 25px;
	color:$black;
	font-size:13px;
	a{
		text-decoration:underline;
		color:$black;
		
		&:hover{
			text-decoration:none;
		}
	}
}







/**Inscription page End**/


