//
// Buyesrs buying Subscription page
// --------------------------------------------------
/************Buyesrs buying Subscription page***************/
.subscription-top-pan{
	padding:16px 30px 16px 35px;
	margin:0 0 30px;
	font-family:$font-family-custom;
	color:$white;
	background: #0097ae; /* Old browsers */
	background: -moz-linear-gradient(top,  #0097ae 0%, #003f59 100%);
	background: -webkit-linear-gradient(top,  #0097ae 0%,#003f59 100%); 
	background: linear-gradient(to bottom,  #0097ae 0%,#003f59 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0097ae', endColorstr='#003f59',GradientType=0 );
	position:relative;
	
	&:after{
		content:"";
		width:210px;
		height:100%;
		position:absolute;
		right:0;
		top:0;
		background-image:url(#{$imageUrl}/subscription-bg.png);
		background-repeat:no-repeat;
		background-position:right center;
		background-size:cover;
	}

	p{
		font-size:15px;
		margin:0;
	}
	.popup-heading02{
		margin:3px 0 9px;
	}
	.price-text{
		float:right;
		position:relative;
		z-index:500;
	}
}


/**Buyesrs buying Subscription End**/


