//
// Home Override
// --------------------------------------------------

/**Variables**/
//colors
$orange-dark: #ee3e23 !default;
$orange-dark2: #ea5118 !default;
$dark-gray: #888888;
$off-white: #e1e1e1;
$dark-blue2: #0d3848;
$dark-blue: #063246;
$light-blue: #69b8d3;
$yellow: #ac9d52;
$move-color: #a1365d;
$border-color: #d1d1d1;

//media css
$screen-1600: 1600px;
$screen-1280: 1280px;
$screen-767: 767px;
$screen-480: 480px;
$screen-680: 680px;
$screen-540: 540px;
$screen-360: 360px;


//**Mixins**//
@mixin border {
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: url("#{$imageUrl}/border.png") repeat-x;
    height: 19px;
    width: 100%;
    display: inline-block;
  }
}

/**Home CSS**/
//buttons
.orange-btn {
  @include bgColor($orange-dark);
}

h2, h3 {
  color: $black;
  font-family: $font-family-custom;
}

ul, li {
  padding: 0;
  list-style: none;
}

.spacer-85 {
  height: 85px;
  clear: both;
  overflow: hidden;
  width: 100%;
}

.spacer-40 {
  height: 40px;
  clear: both;
  overflow: hidden;
  width: 100%;
}

/**Basic CSS**/
h1 {
  color: $white;
  font-size: 55px;
  font-family: $font-family-custom;
  font-weight: lighter;
  margin-bottom: 28px;
}

h3 {
  font-size: 27px;
  font-weight: lighter;
}

h2 {
  font-size: 38px;
  font-weight: lighter;
}

.blue {
  background-color: $dark-blue;
}

.yellow {
  background-color: $yellow;
}

.move {
  background-color: $move-color;
}

.banner-button {
  border: medium none;
  border-radius: 0;
  color: $white;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  padding: 6px 32px;
  font-family: $font-family-custom;
  &:hover{
    text-decoration: none;
    color: $white;
  }
}

.button-border  {
  border: 2px solid $white;
  margin: 0 18px 10px;
  font-size: 23px;
  font-weight: 500;
}

/**Header Part**/
.home.header-part {
  padding: 26px 0;
  background: transparent;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
  .right-menu-pan {
    margin: 0 15px 0 0;
    li {
      display: block;
      float: left;
      margin: 0 11px;
      &:last-child {
        margin-right: 0;
      }
      .connexion {
        border-color: $white;
        color: $white;
      }
    }
  }



  //dropdown-btn
  .dropdown {
    padding-left: 15px;
    padding-right: 10px;
    margin: 8px 0;
    float: left;
    position: relative;
    z-index: 40;
    .dropdown-toggle {
      width: 25px;
      background: transparent;
      border: none;
      padding: 0;
      span.icon-bar {
        display: block;
        width: 100%;
        height: 4px;
        margin: 3px 0;
        font-size: 0;
        background-color: $white;
        border-radius: 8px;
      }
    }
  }
}

/**Banner Part**/
.banner-home-pan {
  background-color:$dark-gray;
  background-image: url("#{$imageUrl}/header-banner.jpg");
  background-size: cover;
  background-position:top center;
  background-repeat:no-repeat;
  min-height: 476px;
  margin-bottom: 0 !important;

  .container:before {
    display: none;
  }
  &:after {
    display: none;
  }
  .banner-content {
    color: $white;
    padding: 16.5% 0 3.5%;
    p {
      margin-bottom: 65px;
      font-size: 1.34em;
      span {
        display: block;
        font-weight: bold;
      }
    }
  }
  .banner-text-box {
    padding: 0 15px;
  }

}

/**Col Box**/
.col-box {
  padding: 4em 0 2.6em;
  color: $black;
  h3 {
    margin-top: 0;
    font-size: 38px;
    font-weight: lighter;
    margin-bottom: 30px;
  }
  //slider
  .row-slider {
    display: inline-block;
    margin-bottom: 50px;
    width: 100%;

    .col-box-cont {
      float: none;
      margin: 0 auto;
      clear: both;
      overflow: hidden;
    }
    p {
      line-height: 26px;
    }
    .carousel-control {
      opacity: 1;
      width: 3%;
      .icon-prev, .glyphicon-chevron-left {
        background: $white url("#{$imageUrl}/sprite.png") no-repeat 6% 60%;
      }
      .icon-prev, .glyphicon-chevron-right {
        background: $white url("#{$imageUrl}/sprite.png") no-repeat 10% 60%;
      }
    }
    span.strong {
      font-family: $font-family-custom;
      font-weight: bold;
      font-size: 22px;
    }

    .carousel-control.left, .carousel-control.right {
      background-image: none !important;
      background: transparent;
    }

    .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-prev, .carousel-control .icon-next {
      font-size: 0px;
      height: 28px;
      margin-top: -10px;
      width: 18px;
    }

    .carousel-indicators {
      bottom: -55px;
      li {
        background: rgba(0, 0, 0, .2);
        border: none;
        border-radius: 10px;
        cursor: pointer;
        display: inline-block;
        height: 10px;
        margin: 1px;
        text-indent: -999px;
        width: 10px;
      }
      .active {
        background-color: rgba(0, 0, 0, .5);
        height: 10px;
        width: 10px;
      }
    }
  }

  //services
  .services {
    margin-top: 30px;
    p {
      margin-top: 10px;
      color: $dark-blue;
      font-size: 23px;
      margin-bottom: 0;
      padding: 0 10px;
    }
    .item {
      background: url("#{$imageUrl}/border-dotted.png") no-repeat right bottom;
      margin: 10px 0;
      &:last-child {
        background: none;
      }
    }
  }
}

/**Testimonials**/
.testimonials {
  background: $dark-gray url("#{$imageUrl}/testimonial-bg.jpg") no-repeat center bottom;
  background-size: 100% auto;
  color: $white;
  padding: 50px 0;
  font-style: italic;
  position: relative;
  font-family: $font-family-custom;
  @include border;

  blockquote {
    border: none;
    margin-bottom: 24px;
  }
  .carousel {
    position: relative;
    padding-top: 7.5%;
  }
  .carousel-inner {
    padding: 0 0 35px 0;
  }
  .carousel-indicators {
    bottom: -25px;
    li {
      background-color: $white;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      display: inline-block;
      height: 8px;
      margin: 3px 15px;
      text-indent: -999px;
      width: 8px;
    }
    .active {
      background-color: rgba(0, 0, 0, .5);
      height: 8px;
      width: 8px;
    }
  }
  .profile {
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 80px;
  }
  .source {
    margin: 0 auto;
    max-width: 56%;
    position: relative;
    display: block;
    font-size: 20px;
  }
  p {
    font-size: 25px;
  }

}

/**Partners*/
.partners {
  padding: 35px 0 30px;
  background-color: #eeefef;
  h2 {
    margin-bottom: 15px;
  }
  li {
    margin: 10px 15px;
    vertical-align: middle;
  }
}

/**Property**/
.property {
  padding: 20px 0 50px;
  background-color: #edf2f4;
  h2 {
    margin-bottom: 35px;
  }
  .item {
    margin-bottom: 10px;

    .title-bar {
      font-family: $font-family-custom;
      text-transform: uppercase;
      color: $white;
      padding: 3px 0;
      width: 80%;
      margin: 0 auto;
      display: inline-block;
      position: relative;
      z-index: 96;
      font-size: 15px;
    }
    .picture {
      margin-top: -18px;
    }
    .desc-box {
      padding: 4px 20px;
      text-align: left;
      background-color: $white;
      h3 {
        margin-top: 10px;
        font-size: 20px;
        color: $dark-blue2;
        border-bottom: 1px solid $border-color;
        padding-bottom: 15px;
        font-weight: bold;
      }
      .desc {
        border-bottom: 1px solid $border-color;
        display: block;
        span {
          font-size: 17px;
          color: $black;
          display: block;
          margin-bottom: 10px;
          font-family: $font-family-custom;
        }
        p {
          font-size: 14px;
        }
      }

      .time-box {
        position: relative;
        text-align: center;
        padding-top: 25px;
        font-family: $font-family-custom;
        .time-title {
          font-size: 13px;
          color: $black;
          margin-bottom: 5px;
        }
        .time {
          font-size: 31px;
          color: $orange-dark2;
          font-family: $font-family-custom;
          line-height: 30px;
        }
        .ref {
          font-size: 13px;
          color: rgba(0, 0, 0, .3);
          font-family: $font-family-custom;
          margin-top: 8px;
        }
        span {
          display: block;
        }

        span.harum {
          position: absolute;
          top: -16px;
          left: 45%;
          border-radius: 50%;
          width: 30px;
          height: 31px;
          padding: 2px 0;
        }
      }
    }
  }
}

/**Comment Bar**/
.comment-bar {
  padding: 30px 0 50px;
  background-color: $light-blue;
  position: relative;
  min-height: 762px;
  @include border;

  h2 {
    color: $white;
  }
  .heading {
    margin-bottom: 40px;
    color: $white;
    padding: 0 15px;
    p {
      font-size: 21px;
      color: rgba(255, 255, 255, .5);
    }
  }
  h3 {
    font-size: 23px;
    color: rgba(0, 0, 0, .5);
    line-height: 22px;
    margin-bottom: 5px;
    font-weight: normal;
  }
  .comment-box {
    background-image: url("#{$imageUrl}/comment-bg.jpg");
    background-size: 100% auto;
    background-position:top center;
    background-repeat:no-repeat;
    margin: 0 auto;
    float: none;
    padding: 10% 3%;
    .col-1 {
      margin: 0 0 0 19%;
      padding: 0 8% 0 0;
    }
    .col-2 {
      margin: 7.5% 0 0;
    }
    .col-3 {
      margin: 4% 0 0 23%;
      padding-right: 54px;
    }
    p {
      color: rgba(0, 0, 0, .5);
      font-size: 15px;
    }
  }

}

/**Footer Top**/
.footer-top {
  background-color: lighten($black, 30%);
  padding: 15px 0 22px;
  h3 {
    font-size: 23px;
    color: $white;
    margin-bottom: 28px;
  }
  .banner-button {
    font-size: 16px;
  }
}

/**Footer**/
.footer-social {
  li {
    &.tumblr {
      a {
        background-position: 34% 100%;
      }
    }
    &.instagram {
      a {
        background-position: 45% 100%;
      }
    }
    &.youtube {
      a {
        background-position: 39% 100%;
      }
    }
    &.nature {
      a {
        background-position: 51% 100%;
      }
    }
  }
}

//navigation-overlay
.navigation-overlay{
  z-index: 90;
}

/**Responsive CSS**/

@media(max-width: $screen-1280) {
  h1 {
    font-size: 45px;
  }
  h2 {
    font-size: 30px;
  }
  .banner-pan .banner-content p {
    font-size: 1.07em;
  }
  .testimonials .source {
    max-width: 70%;
  }
  .comment-bar h3 {
    font-size: 16px !important;
  }

  .comment-bar .comment-box p {
    font-size: 13px;
  }
  .comment-bar .comment-box .col-1 {
    margin: 0% 0 0 18%;
  }

  .property .item .desc-box h3 {
    font-size: 18px;
  }
  .col-box h3 {
    font-size: 30px;
  }
  .col-box .services p {
    font-size: 16px;
  }
  .col-box .services .item {
    background: none;
    img {
      max-width: 50px;
    }
  }
  .comment-bar .comment-box .col-2 {
    margin: 5.5% 0 2%;
  }
  .comment-bar .comment-box .col-3 {
    margin: 3% 0 0 23%;
  }
}

@media(max-width: $screen-sm-max) {
  p {
    font-size: 14px;
  }
  h1 {
    font-size: 35px;
  }
  .banner-pan .banner-content p {
    font-size: 1.1em;
  }
  .banner-pan .banner-content {
    padding: 30% 0 3%;
  }
  .banner-button {
    font-size: 14px;
  }
  .testimonials .source {
    max-width: 90%;
  }
  .col-box .row-slider span.strong {
    font-size: 1.13em;
  }
  .spacer-85 {
    display: none !important;
  }
  .col-box h3 {
    font-size: 22px;
  }
  .property .item .desc-box .desc {
    min-height: 166px;
  }
  .comment-bar .comment-box .col-1 {
    margin: 4% 0 0 18%;
  }
  .comment-bar .comment-box .col-2 {
    margin: 18% 0 2%;
  }

}

@media(max-width: $screen-xs-max) {
  h1 {
    font-size: 25px;
  }
  p {
    margin-bottom: 25px;
  }
  .language-pan {
    position: absolute;
    right: 15px;
    top: -53px;
  }
  .header-part .right-menu-pan {
    margin: 0 auto;
    display: table;
  }
  .testimonials .profile {
    width: 100%;
    position: static;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 23px;
  }

}

@media(min-width: $screen-sm-max) and (max-width: $screen-1280) {
  .comment-bar .comment-box .col-2 {
    margin: 2.5% 0;
  }
}

@media(min-width: $screen-540) and (max-width: $screen-767) {
  .property .item .desc-box .desc span {
    font-size: 13px;
  }
  .property .item .desc-box .time-box .time {
    font-size: 25px;
  }
}


@media(max-width: $screen-767) {
  h1{
    font-size: 22px;
  }
  h2 {
    font-size: 22px;
  }
  .banner-home-pan .banner-content p{
    font-size: 1.1em;
  }
  .banner-home-pan .banner-content {
    color: #fff;
    padding: 130px 0 3.5%;
  }
  .home.header-part .right-menu-pan {
    float: none;
    margin: 0 auto;
    text-align: center;
  }
  .property .item {
    max-width: 350px;
    margin: 0 auto 30px;
  }
  .property .item .desc-box .desc {
    min-height: 100%;
  }
  .comment-bar .heading p {
    font-size: 16px;
  }
  .comment-bar .comment-box {
    background: none;
    padding: 0 15px;
    .col-1 {
      background-image: url("#{$imageUrl}/comment-mobile-col-1.png");
      background-position: left top;
      background-repeat: no-repeat;
      background-size: 100% auto;
      height: 100%;
      padding: 6% 10% 0 18%;
    }
    .col-2 {
      background-image: url("#{$imageUrl}/comment-mobile-col-2.png");
      background-position: left top;
      background-repeat: no-repeat;
      background-size: 100% auto;
      height: 100%;
      padding: 0% 10% 0 18%;
    }
    .col-3 {
      background-image: url("#{$imageUrl}/comment-mobile-col-3.png");
      background-position: left top;
      background-repeat: no-repeat;
      background-size: 100% auto;
      height: 100%;
      padding: 8% 10% 0 18%;
    }
    .col-1, .col-2, .col-3 {
      text-align: left !important;
      margin: 0;
    }
    .footer-top .banner-button {
      font-size: 14px;
    }
  }
  .testimonials {
    p, .source {
      font-size: 20px;
    }
  }
  .col-box .services .item {
    background: none;
    margin-bottom: 30px;
  }
  .col-box .carousel-text {
    text-align: center;
  }
  .header-part .right-menu-pan li:first-child {
    margin-left: 0;
  }
  .banner-button {
    width: 200px;
    padding: 6px 0 !important;
  }
  .partners li{
    margin: 10px 5px;
    img{
    width: 67%;
    margin: 0 auto;
    text-align: center;
  }
  }
  .col-box .row-slider .carousel-control {
    opacity: 1;
    width: 10%;
  }
  .col-box .row-slider .col-box-cont {
    float: left;
    width: 100%;
    margin: inherit;
  }
  .property .item .desc-box h3{
    font-size: 16px;
  }
  .property .item .desc-box .desc span{
    font-size: 15px;
  }
  .footer-top .banner-button {
    font-size: 14px;
  }
}

@media(max-width: $screen-480) {
  h1{
    font-size: 16px;
  }
  h3 {
    font-size: 25px;
  }
  .home.header-part .right-menu-pan li {
    display: inline-block;
    float: none;
    margin: 0 4px;
  }
  .banner-home-pan .banner-content p{
    font-size: .9em;
    line-height: 22px;
  }
  .banner-home-pan .banner-content {
    color: #fff;
    padding-top:150px;
  }
  .right-menu-pan li a {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .banner-home-pan .banner-content p {
    margin-bottom: 40px;
  }
  .testimonials p, .testimonials .source {
    font-size: 14px;
  }
  .comment-bar h3 {
    font-size: 16px !important;
  }
  .right-menu-pan li a{
    font-size: 11px;
  }
  .home.header-part .right-menu-pan li{
    margin: 0 4px;
  }
h2{
  font-size:20px;
}
  .footer-top h3 {
    font-size: 20px;}

}
@media(max-width: $screen-360) {

  .right-menu-pan li{
    margin: 0px;
    display: block;
    text-align: center;
    a{
      font-size: 12px;
      display: inline-block;
    }
  }

}
