//
// Inscription acheteur
// --------------------------------------------------
/************Inscription acheteur page***************/
.important-note{
	display:block;
	text-align:center;
	margin:0 0 18px;
	color:$red;
	font-size:15px;
}

.heading10{
	font-family:$font-family-custom;
	font-size:43px;
	font-weight:300;
	color:$black;
	margin:0 0 30px;
}
.confirmation-text02{
	display:block;
	font-size:19px;
	color:$red;
	font-weight:bold;
	margin:0 0 60px;
	
	a{
		color:$red;
		text-decoration:none;
		
		&:hover,
		&:focus{
			color:$red;
			text-decoration:underline;
		}
	}
}

.checkbox02{
    float: left;
    margin: 6px 20px 0 0;
}

.dotted-border{
	border-color:lighten($black, 75%);
	border-style:dotted;
	margin:0 0 18px;
}
.dotted-border02{
	@extend .dotted-border;
	margin:25px 0 30px;
}

.form-link01{
	display:inline-block;
	font-size:16px;
	color:$textColor;
	text-decoration:underline;
	margin:6px 0 0 0;
	
	&:hover,
	&:focus{
		color:$textColor;
		text-decoration:none;
	}
}

/**Inscription acheteur page End**/


