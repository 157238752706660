//
// Contact
// --------------------------------------------------

//Contact Wrap
.contact-wrap {
  h2 {
    color: #003a54;
    font-size: 20px;
    margin-bottom: 30px;
    font-family: $font-family-custom;
    margin-top: 0;
    border-bottom: 1px dotted #003a54;
    text-transform: uppercase;
    padding-bottom: 11px;
    position: relative;
  }
  //Contact Page
  .contact-page {
    width: 100%;
    display: inline-block;
    padding: 55px 0 40px;
    h3.contact-subtitle {
      position: relative;
      color: $black;
      padding-bottom: 20px;
      border-bottom: 1px solid #c4c4c4;
      margin-bottom: 30px;
      font-size: 22px;
      font-family: $font-family-custom;
      margin-top: 0;
      font-weight: lighter;
      &:after {
        background: #ce2910;
        bottom: -1px;
        content: " ";
        height: 3px;
        left: 0;
        position: absolute;
        transition: left 1s ease 0s;
        width: 40px;
      }
    }
    //Contact Form
    .contact-form{
      padding-left: 0;

      .form-control{
        border: 1px solid #c4c4c4;
        height: 40px;
      }
      textarea.text-border{
        border: 1px solid #c4c4c4;
        width: 100%;
        padding: 10px 12px;
        color: #000000;
        font-family: $primary-font;
      }
    }

    //Contact Map
    .contact-map{
      padding-right: 0;
      .map-wrap{
        position: relative;
      }
      .address {
        background: rgba(47, 48, 54, 0.8) none repeat scroll 0 0;
        bottom: 0;
        color: #ffffff;
        left: 0;
        padding: 10px;
        position: absolute;
        right: 0;
        font-size: 14px;
        font-family: $primary-font;
      }
    }
  }
}
/* Chrome/Opera/Safari */
textarea.text-border::-webkit-input-placeholder{
  color: #000000 !important;
  opacity: 1 !important;
}
/* Firefox 19+ */
textarea.text-border::-moz-placeholder{
  color: #000000 !important;
  opacity: 1 !important;
}
/* IE 10+ */
textarea.text-border:-ms-input-placeholder{
  color: #000000 !important;
  opacity: 1 !important;
}
/* Firefox 18- */
textarea.text-border:-moz-placeholder{
  color: #000000 !important;
  opacity: 1 !important;
}

@media (max-width: 991px){
  .contact-wrap .contact-page .contact-form{
    padding: 0 15px;
  }
  .contact-wrap .contact-page .contact-map{
    padding: 0 15px;
  }
}