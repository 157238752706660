//
// Header
// --------------------------------------------------
/**Header Part**/
.header-part{
	padding:15px 0;
	background-color:$blue;
	position:relative;
}
.brand{
	margin:0;
	padding:0;
	
	strong{
		@include text-hide();
	}
}

.right-menu-part{
	float:right;
}
.right-menu-pan{
	@include list-unstyled;
	float:left;
	margin:0 30px 0 0;
	
	li{
		display:block;
		float:left; 
		margin:0 35px 0 0;
		
		a{
			display:block;
			padding:7px 18px;
			font-size:15px;
			font-family:$font-family-custom;
			text-transform:uppercase;
			color:#ffebc2;
			text-decoration:none;
			border:2px solid #ffebc2;
			position:relative;
			@include transition(all .2s ease-in-out);
			
			span{
				color:$white;
			}
			
			&.connexion{
				padding-left:36px;
				
				&:after{
					@include after();
					width:12px;
					height:12px;
					left:12px;
					top:11px;
					background-position:-270px 0;
				}
			}
		}
		
		
		&:hover,
		&.active{
			a{
				background:$blue;
				border-color:$white;
				color:$white;
				background:darken($blue, 4%);
			}
		}
		
		&.after-login-menu{
			margin:22px 5px 0 -20px;
			
			a{
				padding:0 16px 0 0;
				font-size:12px;
				border:none;
				background:none;
				color:$white;
				font-family:$primary-font;
				text-transform:none;
				
				&:after{
					@include after();
					width:9px;
					height:5px;
					background-position:-40px -45px;
					right:0;
					top:6px;
				}
			}
			
			&:hover,
			&:active{
				a{
					color:$orange;
					text-decoration:none;
				}
			}
		}
		
	}
}

.language-pan{
	float:left;
	margin:15px 15px 0 0;
	@include list-unstyled;
	
	li{
		display:block;
		float:left;
		padding:0 5px;
		position:relative;
		font-family:$font-family-custom;
		font-size:15px;
		color:$white;
		text-transform:uppercase;
		
		&:after{
			content:"I";
			position:absolute;
			left:100%;
			top:0;
			margin-left:-2px;
		}
		&:last-child:after{
			display:none;
		}
		a{
			color:$white;
			position:relative;
			display:block;
			
			&:after{
				content:"";
				width:100%;
				height:3px;
				background-color:#ee3e23;
				position:absolute;
				left:0;
				top:100%;
				opacity:0;
				@include transition(all .2s ease-in-out);
				
			}
		}
		
		&:hover,
		&.active{
			a{
				text-decoration:none;
				&:after{
					opacity:1;
				}
			}
		}
	}
}



//Banner part
.banner-pan{
	background-color:#edf2f4;
	margin:0 0 62px;
	position:relative;
	&:after{
		content:"";
		position:absolute;
		left:0; 
		top:100%;
		width:100%;
		height:35px;
		background:url(#{$imageUrl}/banner-bottom-bg.png) repeat;
	}
	.container{
		position:relative;
		
		&:before{
			content:"";
			position:absolute; 
			right:-20px;
			top:0;
			height:100%;
			width:100%;
			display:block;
			background:url(#{$imageUrl}/inscription-banner-image.jpg) no-repeat right center / auto 100%;
		}
	}
	
	p{
		font-size:18px;
		font-family:$font-family-custom;
	}
	
	article{
		position:relative;
		z-index:200;
	}
	
	&.inscription-acheteur-banner{
		
		&:before{
			content:"";
			position:absolute; 
			right:0;
			top:0;
			height:100%;
			width:100%;
			display:block;
			background:url(#{$imageUrl}/inscription-banner-image02.jpg) no-repeat right center / auto 100%;
		}
		
		.container{
			&:before{
				display:none;
			}
		}
	}
}


.banner-heading01{
	color:$blue;
	font-size: 36px;
	font-weight:300;
	margin:22px 0 15px;
	line-height:1.1;
	font-family:$font-family-custom;
	
	span{
		display:block;
		font-size:57px;
		margin-left:110px;
	}
}



/**Header Part End**/


