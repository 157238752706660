//
// Seller Account 3
// --------------------------------------------------
/************Seller Account 3 page***************/
.heading05{
	color:$black;
	font-size:17px;
	margin:0 10px 10px;
	font-weight:400;
	
	mark{
		background:none;
		color:$red;
		font-weight:bold;
		padding:0;
	}
}
.seller-quick-detail-pan{
	border-top:1px solid lighten($black, 75%);
	border-bottom:1px solid lighten($black, 75%);
	background:lighten($black, 98%);
	margin:0 0 15px;
	padding:25px 30px;
	color:$black;
}

.seller-profile-image{
	display:block;
	text-align:center;
	
	img{
		display:block;
		margin:0 auto 16px;
		border-radius:50%;
	}
}

.heading06{
	font-family:$font-family-custom;
	font-size:32px;
	margin:0 0 15px;
	color:$blue;
	
	small{
		font-size:22px;
		color:$red;
		display:block;
	}
}

.seller-detail-list{
	@include list-unstyled;
	margin:0 0 15px;
	padding:0;
	
	li{
		font-size:15px;
		color:$black;
		display:block;
		margin-bottom:2px;
		
		a{
			color:$black;
			text-decoration:none;
			
			&:hover{
				text-decoration:underline;
			}
		}
	}
}

.offer-text{
	display:block;
	border-right:1px solid lighten($black, 40%);
	color:$blue;
	font-size:17px;
	font-family:$font-family-custom;
	text-align:center;
	margin-right:-10px;
	padding-right:10px;
	
	mark{
		background:none;
		color:$red;
		display:block;
		margin:8px 0 0 0;
		padding:0;
		font-size:48px;
		font-weight:300;
		line-height:1;
		letter-spacing:-2px;
	}
	
	sup{
		font-size: 20px;
		font-weight: 400;
		margin-left: -5px;
		top: -30px;
	}
	
}
.paragraph02{
	font-size:14px;
	line-height:1.8;
	margin-bottom:8px;
}

/**Seller Account 4 page End**/


