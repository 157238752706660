//
// Button
// --------------------------------------------------
.btn{
	color:$white;
	border:none;
	border-radius:0;
	padding:10px 28px;
	border:none;
	font-weight:bold;
	font-size: $font-size-base;
	display:inline-block;
	
	&.btn-sm{
		font-size: ceil(($font-size-base * 1.07));
		padding:8px 22px;
	}
	&.btn-dashboard{
		font-family:$font-family-custom;
		border-radius:5px;
		font-size:13px;
		padding:5px 12px;
	}
}
.red-btn{
	@include bgColor($red);
	margin:10px 5px 60px;
	
	&:hover,
	&:focus{
		color:$white;
		outline:none;
	}
}

.orangr-btn{
	@include bgColor($orange);
	
	&:hover,
	&:focus{
		color:$white;
		outline:none;
	}
}

.orangr-dark-btn{
	@include bgColor(#ea5118);
	
	&:hover,
	&:focus{
		color:$white;
		outline:none;
	}
}

.green-button{
	@include bgColor(#71cb11);
	margin:0 5px 40px;
	padding:13px 40px;
	font-size: ceil(($font-size-base * 1.28));
	
	&:hover,
	&:focus{
		color:$white;
		outline:none;
	}
}

.gray-button{
	@include bgColor(lighten($black, 61%));
	margin:0 5px 40px;
	padding:13px 40px;
	font-size: ceil(($font-size-base * 1.28));
	
	&:hover,
	&:focus{
		color:$white;
		outline:none;
	}
}

.blue-btn{
	@include bgColor($blue);
	
	&:hover,
	&:focus{
		color:$white;
		outline:none;
	}
}

.login-button{
	font-size:$font-size-base;
	font-weight:bold;
	color:$white;
	width:110px;
	height:32px;
	border:2px solid $white;
	display:block;
	margin:0 auto 20px;
	@include bgColor(#62aecf);
}


.btn.disabled, 
.btn[disabled], 
fieldset[disabled] .btn{
	background:lighten($black,86%);
	&:hover,
	&:focus{
		background:lighten($black,86%);
		color:$white;
	}
}
