//
// Responsive
// --------------------------------------------------
/****************Responsive part***********/

@media(max-width : $screen-1366) {

.banner-pan{
	&.inscription-acheteur-banner{
		&:before{
			background-position:140% center;
		}
	}
}
	
}

@media(max-width : $screen-1280) {

.banner-pan{
	&.inscription-acheteur-banner{
		&:before{
			background-position:160% center;
		}
	}
}
	
}


@media(max-width : $screen-sm-max) {

.main-navbar .dropdown:hover .dropdown-menu,
.navbar-toggle{
	display : block;
}
	
}


@media (max-width: $screen-md-max) {
/***Banner***/
.banner-pan {
	&.inscription-acheteur-banner{
		&:before{
			background-position: 380px center;
		}
	}
	p{
		font-size:17px;
	}
}
/************Create your bidding page***************/
.create-tender-container{
	padding: 0 15px 5px;
	
	h3{
		font-size:22px;
		margin-bottom:18px;
	}
	
	&.red-color{
		h3{
			font-size:20px;
			margin-bottom:15px;
		}
	}
}

.tender-no{
	font-size:38px;
	width:62px;
	height:76px;
	top:-38px;
	margin-bottom:-18px;
}

.seller-info-tag{
	li{
		a{
			padding:0 8px;
		}
	}
}

/************Buyers landing***********/
.buyers-left-pan{
	padding:30px 20px 5px;
	h3{
		font-size:30px;
	}
	.btn{
		top:25px;
	}
}
.buyers-heading02{
	margin-top:0;
}

.search-pan{
	h2{
		font-size:22px;
		margin-top:3px;
	}
}

.price-text{
	font-size:14px;
	big{
		font-size:95px;
		
		sup{
			font-size:30px;
			top:-52px;
			margin-left:-15px;
			span{
				font-size:24px;
			}
		}
	}
	.price-text-2{
		font-size:24px;
		margin-left:-41px;
		top:-28px;
	}
	.price-text-3{
		margin-left:-46px;
	}
}

/************Buyesrs Auction page***************/
.table-wrapper{
	&.smaill-table{
		.table{
			> tbody {
				> tr{
					> th{
						padding:8px;
						font-size:13px;
					}
				}
			}
		}
	}
}
/*******Detail Auction*******/
.open-auction-pan{
	.price-circle-pan{
		margin-left:-8px;
	}
}

.detail-auction-pan{
	.selesroom-box{
		h3{
			font-size:19px;
		}
	}
	
	.selesroom-tag-list{
		li{
			font-size:15px;
		}
	}
}

.popup-bottom-link{
	li{
		margin-right:14px;
	}
}


/******Won Auction******/
.complete-description-btn{
	font-size:14px;
}
.seller-info-inner-text{
	padding:0 20px 15px;
}
.seller-info-pan{
	h4{
		font-size:18px;
		padding:15px 10px;
	}
}


}


@media (max-width: $screen-sm-max) {
body{
	font-size: ceil(($font-size-base * 1.07));
}
/**************inscription page*********/
.right-slide-panel{
	width:400px;
}
.right-menu-pan{
	margin-right:10px;
	
	li{
		margin-right:20px;
		a{
			font-size:14px;
			padding:6px 12px;
			&.connexion{
				padding-left:30px;
				&:after{
					left:11px;
					top:10px;
				}
			}
		}
		&.after-login-menu{
			margin:18px 0 0 -8px;
		}
	}
}
.language-pan{
	margin:13px 0 0 0;
}
.brand{
	display:block;
	width:200px;
}
.banner-pan{
	&:after{
		height:25px;
	}

	&.inscription-acheteur-banner{
		&:before{
			background-image:url(#{$imageUrl}/inscription-banner-image-mobile.jpg);
			background-position: 240px center;
		}
	}

	.container{
		&:before{
			right:0;
			background-position:340px center;
		}
	}
	
	p{
		font-size:16px;
	}
}
.banner-heading01{
	font-size:30px;
	margin:14px 0 12px;
	span{
		font-size:42px;
		margin-left:40px;
	}
}

/**************inscription thank you page*********/
.heading-border{
	font-size:30px;
	span{
		padding:0 25px;
	}
}

/************Mandats information page***************/
.red-btn{
	margin-bottom:40px;
}
.main-menu li a{
	font-size:22px;
	padding:5px 15px;
}
.left-question-pan{
	position:relative !important;
    margin: -30px auto 20px !important;
    max-width: 290px;
}

/************Create your bidding page***************/
.bidding-text-container{
	min-height:inherit;
	margin-bottom:20px;
	
	p{
		font-size:16px;
	}
}

.create-tender-container{
	margin:60px 0 40px;
	min-height:inherit;
}

.heading02{
	margin-bottom:30px;
}
.form-box-pan {
    margin-bottom: 40px;
}

/***********My Account Help***********/
.gray-container{
	padding-bottom:20px;
}
.help-banner-pan{
	font-size:16px;
	article{
		padding:35px 0 5px;
		
		&:after{
			right:0;
			width:150px;
			height:115px;
		}
	}
}
.banner-heading02{
	font-size:20px;
}

.help-content-box{
	padding:25px 25px 5px;
	
	h3{
		font-size:18px;
		margin-bottom:15px;
	}
}

.help-left-pan-heading{
	font-size:17px;
	a{
		padding:30px 0 0 20px;
	}
}
.left-pan-menu > li{
	> a{
		font-size:17px;
		padding:8px 0 3px 20px;
	}
	ul li a{
		padding:5px 0 5px 15px;
	}
}


/********************My Tenders******************/
.heading-pan{
	margin-bottom:38px;
	padding:18px 0 10px;
	
	&:after{
		height:16px;
	}
	
	h1{
		font-size:26px;
	}
}

.table-wrapper{
	margin-bottom:45px;
	padding-top:8px;
}
.table {
	> tbody {
		> tr {
			> th{
				font-size:14px;
				padding:7px 10px;
			}
			>td{
				font-size:13px;
			}
		}
	}
}

.seller-top-menu{
	margin:0 -5px;	
	li{
		padding:0 5px;
		margin-bottom:20px;
		a{
			font-size:13px;
			padding:28px 10px 5px;
			
			&:after{
				top:8px;
				right:10px;
			}
		}
	}
}
.seller-info-tag{
	padding:12px 0 8px;
	margin-bottom:40px;
	li{
		font-size:11px;
		strong{
			padding-right: 6px;
		}
		a{
			padding: 0 6px;
		}
	}
}
.seller-form-container {
    margin-bottom: 45px;
}
.back-page-link{
	font-size:12px;
	margin:10px 0 -10px;
	padding:8px 10px 8px 30px;
	position:static;
	width:100%;
	text-align: right;
	
	span{
    	bottom: inherit;
		display: inline-block;
		left: inherit;
		margin-left: 15px;
		position: relative;
		right: 5px;
	}
}
.heading04{
	font-size:25px;
	margin-bottom:25px;
}

.heading05{
	font-size:16px;
	margin:0 0 12px;
}

.heading06{
	font-size:26px;
	small{
		font-size:18px;
	}
}
.seller-detail-list{
	li{
		 font-size:14px;
	}
}
.paragraph02{
	line-height:1.4;
}

/***The accesses page*****/
.heading-container{
	margin-bottom:40px;
}
.heading07{
	font-size:18px;
	margin-bottom:12px;
}
.chenge-pwd-link{
	font-size:13px;
}

/************Buyers landing***********/
.dashboard-banner-pan{
	margin-bottom:0;
	padding-top:25px;
}
.dashboard-user-pan{
	margin:0 0 25px;
}
.dashboard-user-name{
	 font-size:38px;
	 
	 small{
		font-size:14px;	
	}
}
.paragraph03{
	font-size:17px;
	margin-bottom:30px;
}

.buyers-right-part{
	margin-bottom:60px;
}
.banner-right-box{
	text-align:center;
	margin:0;
	
	li {
		padding: 0 8px;
	}
}

.buyers-left-pan {
    margin: 0 0 50px;
}

.subscribe-pan{
	margin-top:0x;
}

.search-pan{
	margin-bottom:30px;
	
	h2{
		float: none;
		margin: 0 0 6px;
		text-align: left;
		width: auto;
		padding:0;
	}
	.search-select-form{
		width:22%;
	}
	.search-input-form{
		width:11%;
	}
}

.selesroom-box{
	padding:20px 15px 15px;
	margin-bottom:40px;
	h3{
		font-size:17px;
	}
	.col-sm-6{
		&.text-center{
			margin-left:-5px;
		}
	}
}

/********Buyesrs buying credit page********/
.buying-right-pan{
	margin-top:10px;
}

/********Buying credit thanks page********/
.credit-thanks-heading{
	font-size:22px;
	margin-bottom:30px;
	padding-top:10px;
	
	.like-icon{
		margin-right:10px;
	}
}

.thank-you-message{
	font-size:19px;
	margin-bottom:50px;
}

/********Buyers buying subscription**********/
.subscription-top-pan{
	padding:12px 20px 15px 25px;
	margin-bottom:25px;
	
	.price-text{
		float:none;
	}
}
.price-text-container{
	margin:20px 0 0;
	text-align:center;
}
.heading-border{
	margin-top:30px;
}

/****Buyesrs Invoice page****/
.invoice-download-link{
	padding-right:28px;
}
.buying-right-pan{
	.table-wrapper{
		margin-bottom:20px;
	}
}
.invo-subs-text{
	font-size:13px;
}
.worning-red-icon{
	margin:0 6px 0 0;
}

/************Buyesrs Auction page***************/
.table-wrapper{
	&.smaill-table{
		overflow:auto;
		.table{
			max-width: inherit;
			width: 780px;
		}
	}
}

/*******Detail Auction*******/
.open-auction-pan{
	.price-circle-pan{
		margin-left:auto;
	}
}
.detail-auction-pan{
	margin-top:10px;
	.selesroom-box{
		padding:30px 20px 30px;
		
		h3{
			font-size:20px;
		}
		p{
			font-size:14px;
		}
		
		.ref-no-text{
			position:static;
			margin-bottom:4px;
			text-align:right;
		}
	}
	.salesroom-tag-container {
		margin-bottom: 4px;
		margin-top: -45px;
	}
	.salesroom-tag {
		font-size: 15px;
		line-height: 24px;
	}
	
	.selesroom-tag-list{
		font-size:14px;
	}
	
	.countdown{
		margin-bottom:20px;
	}
}


.deatil-share-pan{
	margin-bottom:30px;
}

.ekko-lightbox {
	.modal-dialog{
		margin:30px;
	}

}

/***********Won Auction*********/
.won-auction-top-pan{
	font-size:17px;
	
	big{
		font-size:70px;
	}
	
	small{
		font-size:16px;
	}
}

/*******Inscription acheteur page********/
.heading10{
	font-size:30px;
	margin-bottom:25px;
}
.confirmation-text02{
	font-size:17px;
	margin-bottom:40px;
}

.form-link01{
	font-size:15px;
	margin-top:5px;
}


}


@media (max-width: $screen-xs-max) {
body{
	font-size: $font-size-base;
}
/**************inscription page*********/
.header-part{
	padding:15px 0 5px;
}
.brand {
    display: block;
    margin: 0 0 18px;
    text-align: center;
    width: 180px;
}
.language-pan {
    position: absolute;
    right: 15px;
    top: -53px;
}

.right-menu-part {
    float: none;
}
.right-menu-pan {
    margin-right: 0;
	float: none;
	text-align:center;
	li {
		text-align:left;
		display: inline-block;
		float:none;
		margin: 0 3px 10px;
		a{
			font-size:14px;
			padding:6px 14px;
			
			&.connexion:after{
				top:10px;
			}
		}
		
		&.after-login-menu{
			margin:2px 0 10px
		}
	}
}

.right-slide-panel{
	width:75%;
	
	.connexion{
		padding:6px 13px 6px 36px;
		margin:10px 0 18px 10px;
		font-size:13px;
		&:after{
			top:10px;
		}
	}
	&.after-login-menu{
		margin:15px 0 15px 10px;
	}
}
.right-inner-part{
	left:45px;
}
.close-right-pan{
	width:45px;
	height:45px;
	background-position: -90px -113px;
}
.menu-social-part{
	width:45px;
}
.menu-social li a{
	width:45px;
	height:45px;
}

.login-form {
	margin-bottom:15px;
	.form-control{
		font-size:18px;
		height:38px;
	}
}

.forgot-pwd-link{
	margin:-15px 10px 22px 0;
}
.login-button{
	font-size:12px;
	width:90px;
	height:30px;
}

.banner-pan{
	margin-bottom:40px;
	
	&:after {
		height: 15px;
	}
	
	.container:before {
		background-position: center center;
		background-size: cover;
		height: 90px;
		left: 0;
		position: relative;
		right: inherit;
	}
	p {
		font-size: 15px;
	}
	
	/***Banner***/
	&.inscription-acheteur-banner{
		&:before{
			position:relative;
			height:80px;
			background-position: center;
		}
	}

}
.banner-heading01 {
    font-size: 24px;
    margin: 10px 0;
	span {
		font-size: 32px;
		margin-left: 15px;
	}
}
.heading-icon {
    font-size: 18px;
    margin: 0 0 25px;
    padding: 4px 0 6px 52px;
	
	 i{
		width:42px;
		height:36px;
		&:after{
			top:2px;
		}
	 }
}

.privacy-checkbox {
    margin: 10px 0 20px;
}
.captcha-container{
	margin-bottom:30px;
}
.terms-text{
	font-size:13px;
}

.green-button{
	padding:8px 25px;
	margin-bottom:25px;
	font-size:16px;
}

//Footer part
.footer-part{
	padding-top:20px
}

.footer-links{
	margin-bottom:25px;
	li{
		margin-bottom:8px;
	}
}
.footer-social li{
	margin:0 6px;
}
/**************inscription thank you page*********/
.thankyou-text-pan{
	min-height:inherit;
}
.heading-border{
	font-size:22px;
	margin-bottom:20px;
	&:after{
		display:none;
	}
	
	span{
		padding:0 25px;
	}
}
.paragraph01 {
    font-size: 15px;
    margin-bottom: 20px;
}

/************Mandats information page***************/
.main-menu li a{
	font-size:16px;
	padding:5px 10px;
}
.datepicker-from{
	&:after{
		background-size:100% auto;
		width:17px;
		left:8px;
		top:6px;
	}
	.datetimepicker{
		padding-left:32px;
	}
}
.btn{
	padding:8px 20px;
}
.red-btn{
	margin:0 0 30px;
}
.left-question-pan{
	margin-top:-20px !important;
}

/************Create your bidding page***************/
.bidding-text-container{	
	p{
		font-size:15px;
	}
}

.create-tender-container{
	margin:50px 0 30px;
	
	h3{
		font-size:18px;
		margin-bottom:15px;
	}
	
	p{
		font-size:16px;
	}
	
	&.red-color{
		h3{
			font-size:18px;
			margin-bottom:12px;
		}
	}
}
.tender-no{
	font-size:28px;
	width:50px;
	height:58px;
	top:-28px;
	margin-bottom:-14px;
}

/***************Your property for sale page*************/
.heading02{
	margin-left:0;
	font-size:16px;
}

.checkbox-heading{
	margin:5px 0 20px;
}
.form-box-pan{
	margin-bottom:25px;
}
.checkbox-list{
	margin:0 10px 26px;
}
.checkbox-heading02{
	font-size:16px;
	margin:14px;
}

/***************Your property for sale Confirm page*************/
.confirmation-container {
    margin: 0 0 20px;
    min-height: inherit;
	p{
		margin-bottom:40px;
	}
	.btn{
		margin:0 0 10px;
	}
}
.confirmation-heading{
	font-size:17px;
	margin-bottom:15px;
}
.btn.btn-sm, .btn-group-sm > .btn {
    font-size: 13px;
    padding: 7px 14px;
}
/***********My Account Help***********/
.gray-container{
	padding:20px 0 10px;
}
.help-banner-pan{
	font-size:15px;
	article {
		padding: 20px 0 1px;
		&:after{
			width:110px;
			height:90px;
		}
	}
}
.banner-heading02{
	font-size:18px;
	margin-bottom:10px;
	span{
		font-size:16px;
	}
}
.help-content-box {
    padding: 15px 15px 0;
	h3 {
		border-width: 2px;
		font-size: 16px;
		margin-bottom: 12px;
		padding-bottom: 10px;
	}
}

/********************My Tenders******************/
.heading-pan{
	margin: 0 0 28px;
    padding: 14px 0 8px;
	
	&:after{
		height:12px;
	}
	
	h1{
		font-size:22px;
	}
}
.heading03{
	font-size:14px;
	margin-bottom:20px;
}

.table-wrapper{
	margin-bottom:35px;
	padding-top:8px;
	overflow:auto;
}
.table {
	max-width:inherit;
	width:600px;
	> tbody {
		> tr {
			> th{
				font-size:13px;
				padding:6px 8px;
			}
			>td{
				font-size:12px;
			}
		}
	}
}

.heading04 {
    font-size: 20px;
    margin-bottom: 20px;
}
.seller-top-menu {
	li{
		width:50%;
	}
}

.seller-info-tag{
	margin-bottom:30px;
	li{
		font-size:13px;
		&:first-child{
			width:100%;
			a,
			strong{
				padding:0 8px;
			}
			
			&:after{
				display:none;
			}
		}
		a{
			padding:0 8px;
		}
	}
}
.seller-form-container {
    margin-bottom: 30px;
}

.seller-quick-detail-pan{
	padding:20px 15px;
}
.seller-profile-image{
	img{
		margin-bottom:12px;
	}
}

.heading04{
	font-size:18px;
	margin-bottom:15px;
}

.heading05{
	font-size:15px;
}

.heading06 {
    font-size: 20px;
    text-align: center;
	small{
		font-size:16px;
	}
}
.seller-detail-list{
	margin-bottom:25px;
	li{
		font-size:13px;
		text-align:center;
	}
}
.offer-text{
	font-size:16px;
	margin:0 0 15px;
	padding:0 0 10px;
	border-right:none;
	border-bottom:1px solid lighten($black, 40%);
	
	mark{
		font-size:44px;
		margin-top:10px;
		
		sup{
			font-size:18px;
		}
	}
}
.paragraph02{
	font-size:13px;
	margin-bottom:0;
}

/***The accesses page*****/
.heading-container{
	position:relative;
	margin-bottom:35px;
}
.heading07 {
    float: none;
    font-size: 16px;
    margin-bottom: 7px;
}
.chenge-pwd-link{
	position:absolute;
	top:30px;
	margin:0;
	font-size:12px;
	right:0;
}

.edit-profile-container{
	.green-button{
		margin-top:0;
	}
}

/************Buyers landing***********/
.dashboard-banner-pan{
	margin-bottom:0;
	padding-top:15px;
}

.dashboard-user-pan{
	padding:0;
}
.dashboard-user-image {
    margin: 0 auto 10px;
    position: relative;
}
.dashboard-user-name {
    font-size: 26px;
    text-align: center;
	
	small{
		font-size:13px;
	}
}
.banner-right-box{
	li {
		display: block;
		margin: 0 0 12px;
		padding:0;
		width: 100%;
		
		.banner-box-inner{
			padding:10px 10px 60px;
			min-height:inherit;
		}
	}
}
.paragraph03{
	font-size:15px;
	margin-bottom:20px;
}
.buyers-left-pan {
    padding: 15px 15px 5px;
	h3 {
		font-size: 21px;
		margin-bottom: 15px;
	}
	p {
		font-size: 14px;
		line-height: 1.4;
		margin-bottom: 20px;
	}
	.paragraph04 {
		font-size: 16px;
		line-height: 1.2;
		margin: 0 0 16px;
	}
	
	.btn{
		font-size:16px;
		padding:8px 28px;
		top:23px;
	}
}
.price-text {
	big{
		font-size:80px;
		sup {
			font-size: 28px;
			margin-left: -12px;
			top: -44px;
			
			span{
				font-size:22px;
				top:20px;
			}
		}
	}
	.price-text-2 {
		font-size: 20px;
		margin-left: -35px;
		top: -22px;
	}
	
	.price-text-3{
		margin:0 0 0 -41px;
		top:-1px;
	}
}

.buyers-right-part{
	margin-bottom:40px;
	ul{
		margin-bottom:10px;
		li{
			padding:10px 0 10px 55px;
			font-size:15px;
			
			&:before{
				left:10px;
			}
		}
	}
}
.buyers-heading02{
	margin:0 0 15px 15px;
	font-size:20px;
	letter-spacing:1px;
}
.arrow-icon{
	margin-bottom:12px;
}
.skip-link{
	margin-right:10px;
	font-size:13px;
}

.subscribe-pan {
	font-size:14px;
}

.search-pan{
	margin-bottom:25px;
	h2{
		font-size:20px;
	}
	.search-select-form {
		float: none;
		margin: 0 0 8px;
		width: auto;
	}
	label{
		float: none;
		margin: 0 0 8px;
		text-align: center;
		width: auto;
	}
	.search-input-form {
		margin: 0 0 8px;
		padding: 0 8px 0 0;
		width: 50%;
		
		&.last{
			padding-right:0;
		}
	}
	
	.search-ok-btn{
		width:50px;
		margin:0 auto;
		float:none;
		display:block;	
	}
	
}

.selesroom-box{
	h3{
		font-size:16px;
		margin-bottom:10px;
		padding-bottom:8px;
	}
	.col-sm-6{
		&.text-center{
			margin-left:0;
		}
	}
}

.price-circle-pan{
	width:155px;
	height:155px;
}

.selesroom-tag-list{
	margin-bottom:6px;
	li{
		font-size:14px;
	}
}

.ref-no-text{
	margin-top:10px;
}


/********Buyesrs buying credit page********/
.heading08{
	font-size:18px;
	margin-bottom:16px;
	padding-bottom:10px;
}
.select-credit-list {
    margin: 0 0 15px;
	
	li{
		float:none;
		width:100%;
		margin:0 0 15px;
		padding:0;
	}
}
.buying-right-pan{
	margin:30px 0;
	.calculate-part{
		padding-bottom:18px;
		margin-bottom:30px;
	}
}
.heading-border{
	margin-top:25px;
}
.salesroom-tag-container{
	margin-top:-34px;
}

.heading09{
	font-size:17px;
	margin-bottom:20px;
}

/********Buying credit thanks page********/
.credit-thanks-heading{
	font-size:18px;
	margin-bottom:20px;
	padding-top:0;
	
	.like-icon{
		margin-right:6px;
	}
}

.thank-you-message{
	font-size:16px;
	margin-bottom:35px;
}

/********Buyers buying subscription**********/
.subscription-top-pan{
	padding:12px 15px 15px;
	p{
		font-size:14px;
	}
}
.calculate-part {
	li{
		.cal-left-text{
			width:70%;
		}
		.cal-right-text{
			width:30%;
		}
	}
}
.invo-subs-text{
	margin:0;
}

/*******Detail Auction*******/
.detail-auction-pan{
	.selesroom-box{
		margin:30px 0 20px;
		padding:20px 15px 20px;
		
		p{
			font-size:13px;
		}
		
		.ref-no-text{
			font-size:12px;
			right:15px;
			top:5px;
		}
		
		h3{
			font-size:16px;
			margin-bottom:12px;
		}
		
		.selesroom-tag-list{
			margin-bottom:10px;
		}
		
		.salesroom-tag{
			font-size:14px;
		}
		
		.btn.btn-dashboard{
			font-size: 16px;
			padding: 7px 20px;
		}
		
	}
	
	.salesroom-tag-container{
		margin-top:-34px;
	}
	
	.selesroom-tag-list{
		li{
			font-size:14px;
		}
	}
	
	.price-circle-pan{
		width:210px;
		height:210px;
		font-size:15px;
		
		big{
			font-size:62px;
			letter-spacing:-4px;
			
			sup{
				font-size:30px;
				top:-32px;
			}
		}
		
		small{
			font-size:14px;
		}
		
		.bid-place-text{
			font-size:12px;
		}

	}
	
	.timer-heading{
		font-size:16px;
	}
	.countdown{
		margin-bottom:15px;
		
		li{
			font-size:34px;
		}
	}
}

.mix-bid-text{
	font-size:15px;
	margin-bottom:4px;
	text-align:center;
}

.popup-bottom-link{
	padding-bottom:4px;
	margin-bottom:10px;
	
	li{
		float:none;
		margin:0 0 6px;
	}
}

.next-offer-pan{
	margin:20px auto;
	font-size:16px;
}
.next-arrow{
	margin-left:10px;
	top:3px;
}

.ekko-lightbox{
	.modal-dialog {
		margin: 30px 20px;
	}
}

.ekko-lightbox-nav-overlay{
	a{
		&:after{
			width:22px;
			height:44px;
			margin-top:-22px;
		}
		
		&.glyphicon-chevron-right:after{
			background-position:-517px -159px;
			right:10px;
		}
		&.glyphicon-chevron-left:after{
			background-position: -445px -159px;
			left:10px;
		}
	}
}

.detail-field-part{
	padding-bottom:0;
}

/******Won Auction******/
.won-auction-top-pan{
	width:210px;
	height:210px;
	padding:14px 10px;
	font-size:14px;
	
	span{
		letter-spacing:0;
	}
	big{
		font-size:60px;
		letter-spacing:-3px;
		
		sup{
			font-size:22px;
			top:-30px;
		}
	}
	small{
		font-size:14px;
	}
}

.like-icon02{
	margin-bottom:10px;
}

.seller-info-pan{
	h4{
		font-size:16px;
		padding:12px 8px;
		margin-bottom:15px;
	}
}

.seller-info-inner-text {
    padding: 0 15px 10px;
}

.seller-info-text{
	font-size:15px;
	margin-bottom:5px;
}
.seller-tel-text{
	font-size:12px;
	margin-bottom:5px;
}

.complete-description-btn{
	font-size:13px;
	padding:9px 10px 9px 30px;
	
	&:after{
		left:10px;
		top:13px;
	}
}

/********Inscription acheteur*********/
.important-note{
	font-size:13px;
}

.heading10{
	font-size:20px;
	margin-bottom:16px;
}
.confirmation-text02{
	font-size:15px;
	margin-bottom:24px;
}

.form-link01{
	margin-top:0;
	font-size:14px;
}
.dotted-border02 {
    margin: 12px 0 24px;
}



}

