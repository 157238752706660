//
// Variables
// --------------------------------------------------

// Colors part

//Gray color shade.
$black:              	            #000;
$textColor:							#757575;


//Blue color
$blue:                            #003a54 !default;

//Red color
$red:                            #ee3e23 !default;

//Orange color
$orange:                            #f58425 !default;

//Green color
$green:                            #5db70d !default;

// White color
$white:                        #fff;

//Font
$primary-font:						'arial', sans-serif;
$font-family-custom:                 "Roboto Condensed", sans-serif;

//Image path
$imageUrl:                           '../images';

//Screen-width
$screen-1366:                  1366px;
$screen-1280:                  1280px;

