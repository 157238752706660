//
// Footer
// --------------------------------------------------
/*************Footer Part***********/
.footer-part{
	background-color:lighten($black,19%);
	padding:30px 0 0 0;
}
.footer-links{
	margin:0 0 38px;
	@include list-unstyled;
	li{
		display:block;
		margin:0 0 10px;
		
		a{
			color:$white;
			text-decoration:none;
			font-size:13px;
			
			&:hover{
				text-decoration:underline;
			}
		}
	}
}


.footer-social{
	margin:0 0 25px;
	@include list-unstyled;
	text-align:center;
	
	li{
		display:inline-block;
		margin:0 10px;
		a{
			display:block;
			@include transition(all .2s ease-in-out);
			width:22px;
			height:14px;
			font-size:0;
			text-indent:-999999px;
			background-image:url(#{$imageUrl}/sprite.png);
			background-repeat:no-repeat;	
			background-position:-426px -103px;
			
			&:hover{
				opacity:.9;
			}
		}
		&.linkedin{
			a{
				background-position:-455px -103px;
			}
		}
		&.twitter{
			a{
				background-position:-487px -103px;
			}
		}
		&.google-plus{
			a{
				background-position:-516px -103px;
			}
		}
	}
}

.footer-logo{
	display:block;
	margin:0 auto 6px;
	width:128px;
	
	strong{
		@include text-hide();
	}
}

.copyright-container{
	background-color:lighten($black,13%);
	padding:12px 0;
	text-align:center;
	font-size:11px;
}



/**Footer Part End**/


