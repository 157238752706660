//
// Buyesrs Detail Auction page
// --------------------------------------------------
/************Buyesrs Detail Auction page***************/
.detail-auction-pan{
	margin:32px 0 40px 0;
	
	.selesroom-box{
		margin:40px 0 20px;
		padding:44px 25px 30px;
		position:relative;
		
		p{
			font-size:15px;
			color:lighten($black, 46%);
			line-height:1.4;
		}
		
		h3{
			font-size:22px;
			text-align:left;
			border:none;
			margin:0 0 14px;
			padding:0;
		}
		
		.ref-no-text{
			position:absolute;
			top:12px;
			right:18px;
			font-size:13px;
		}
		
		.btn.btn-dashboard{
			font-size:19px;
			padding:10px 24px;
			font-weight:400;
		}
		
	}
	.salesroom-tag-container{
		margin-top:-60px;
		margin-bottom:30px;
	}
	.salesroom-tag{
		line-height:28px;
		font-size:18px;
	}
	
	.selesroom-tag-list {
		border-bottom:1px dotted $blue;
		li{
			font-size:16px;
		}
	}
	
	//Right pan
	.price-circle-pan{
		width:230px;
		height:230px;
		font-size:17px;
		padding-top:42px;
		margin-bottom:22px;
		
		span{
			margin-bottom:5px;
		}
		
		big{
			font-size:75px;
			letter-spacing:-5px;
			
			sup{
				font-size:38px;
				top:-42px;
			}
		}
		
		small{
			font-size:16px;
		}
		
		.bid-place-text{
			font-size:14px;
			color:$red;
		}
	}
	
	
	.timer-heading{
		font-size:18px;
	}
	
	.countdown{
		margin-bottom:30px;
		li{
			font-size:42px;
			font-weight:400;
		}
	}
	
	
	
	
}

.deatil-share-pan{
	margin:0 0 10px;
}

.mix-bid-text{
	display:block;
	font-size:17px;
	font-family:$font-family-custom;
	margin:0 0 5px;
	color:lighten($black, 46%);
	
	span{
		color:$red;
	}
}


.next-offer-pan{
	border:1px solid lighten($black, 97%);
	width:170px;
	padding:10px;
	margin:28px auto 25px;
	text-align:center;
	color:$black;
	font-family:$font-family-custom;
	font-size:18px;
	color:$blue;
}
.next-offer-text-1{
	display:block;
	margin:0 0 10px;
}
.next-offer-text-2{
	display:block;
	color:$red;
	font-size:34px;
	letter-spacing:-2px;
	
	sup{
		font-size:17px;
		top:-16px;
	}
}
.price-icon{
	display:block;
	width:21px;
	height:21px;
	margin:0 auto 8px;
	background:url(#{$imageUrl}/sprite.png) no-repeat -90px 0;
}

.popup-container{
	margin:0 0 14px;
}

#gallery-big{
	.item{
		
		a{
			display:block;
			position:relative;
			
			&:after{
				@include after();
				width:19px;
				height:19px;
				background-position:-270px -131px;
				right:10px;
				bottom:10px;
				@include transition(all .2s ease-in-out);
			}
			
			&:hover{
				&:after{
					OPACITY:.8;
				}
			}
		}
	}
}

#gallery-thumb{
	margin:10px -5px 0;
	width:auto;
	.owl-item{
		padding:0 5px;
	}
	.item{
		background-position:center;
		background-repeat:no-repeat;
		background-size:cover;
		height:55px;
		
	}
}

.btn.btn-sm{
	&.next-button{
		background:none;
		border:1px solid lighten($black, 85%);
		color:$black;
		font-family:$font-family-custom;
		font-size:13px;
		font-weight:400;
		text-transform:uppercase;
		@include transition(all .2s ease-in-out);
		
		&:hover{
			border-color:$red;
			color:$black;
		}
	}
}
.next-arrow{
	display:inline-block;
	position:relative;
	top:3px;
	width:8px;
	height:15px;
	margin:0 0 0 14px;
	background:url(#{$imageUrl}/sprite.png) no-repeat -163px -167px;
}

//Popup Gallery
.modal-header{
	display:none;
}

.ekko-lightbox{
	.modal-dialog{
		margin:30px auto;
	}
}

.ekko-lightbox-nav-overlay{
	a{
		text-indent:-99999px;
		font-size:0;
		
		&:after{
			@include after();
			width:43px;
			height:80px;
			top:50%;
			margin-top:-40px;
		}
		
		&.glyphicon-chevron-left{
			&:after{
				background-position:-445px -140px;
				left:20px;
			}
		}
		&.glyphicon-chevron-right{
			&:after{
				background-position:-495px -140px;
				right:20px;
			}
		}
	}
}

.popup-bottom-link{
	@include list-unstyled;
	@include clearfix;
	border-bottom:1px dotted $blue;
	margin:0 0 12px;
	padding:0 0 10px;
	li{
		display:block; 
		float:left;
		margin:0 35px 0 0;
		
		&:last-child{
			margin-right:0;
		}
		
		a{
			font-size:13px;
			text-transform:uppercase;
			color:lighten($blue, 10%);
			text-decoration:none;
			padding:0 0 0 28px;
			position:relative;
			
			&:after{
				@include after();
				width:19px;
				height:13px;
				position:absolute;
				left:0;
				top:3px;
			}
			
			&.see-video-link{
				&:after{
					background-position:-229px -157px;
				}
			}
			&.all-features-link{
				padding-left:22px;
				&:after{
					background-position:-232px -182px;
				}
			}
		}
	}
}

.detail-field-part{
	padding:20px 0 10px;
}


//Open Auction
.open-auction-pan{
	.heading08{
		margin-bottom:30px;
	}
}

/**Buyesrs Detail Auction End**/


