//
// Buyesrs buying credit page
// --------------------------------------------------
/************Buyesrs buying credit page***************/
.salesroom-box{
	border:1px solid $blue;
	padding:1px;
	margin:0 0 14px;
	font-family:$font-family-custom;
	text-align:center;
	
	ul{
		@include list-unstyled;
		margin:0 0 8px;
		padding:0;
		
		li{
			display:block;
			margin:0 0 5px;
			font-size:15px;
			color:$blue;
			
			a{
				color:$blue;
				text-decoration:none;
				
				&:hover,
				&:focus{
					outline:none;
					color:$blue;
					text-decoration:underline;
				}
			}
		}
	}
}
.salesroom-heading{
	font-size:20px;
	color:$white;
	font-weight:700;
	background-color:$red;
	padding:7px 5px 8px;
	margin:0 0 7px;
}

.salesroom-icon{
	width:33px;
	height:32px;
	display:block;
	margin:7px auto 8px;
	background-image:url(#{$imageUrl}/sprite.png);
	background-repeat:no-repeat;
	
	&.key-icon{
		background-position:-160px -45px;
	}
	&.coin-icon{
		background-position:-210px 0;
	}
	&.bell-icon{
		background-position:-215px -43px;
	}
}

.salesroom-subheading{
	font-size:15px;
	font-weight:700;
	color:$blue;
	margin:0 0 7px;
	
	a{
		color:$blue;
		text-decoration:none;
		
		&:hover,
		&:focus{
			color:$blue;
			text-decoration:underline;
		}
	}
	
	span{
		color:$red;
	}
}
.salesroom-heading02{
	font-size:20px;
	font-weight:300;
	margin:0 0 8px;
	color:$blue;
	
	a{
		color:$blue;
		text-decoration:none;
		
		&:hover,
		&:focus{
			color:$blue;
			text-decoration:underline;
		}
	}
	
	span{
		color:$red;
	}
}

.salesroom-links-pan{
	display:block;
	margin-bottom:16px;
	font-size:16px;
	font-weight:700;
	
	a{
		color:$red;
		text-decoration:none;
		
		&:hover,
		&:focus{
			color:$red;
			text-decoration:underline;
		}
	}
}

.buying-right-pan{
	margin:32px 0 40px 0;
	
	.calculate-part{
		padding:0;
		margin:0 0 38px;
		font-family:$font-family-custom;
		
		li{
			color:$black;
			border-bottom:1px solid lighten($black, 70%);

		}
	}
	
	.table-wrapper{
		margin-bottom:30px;
	}
}
.heading08{
	font-size:20px;
	color:$blue;
	text-transform:uppercase;
	font-weight:400;
	padding:0 0 12px;
	font-family:$font-family-custom;
	border-bottom:1px dotted $blue;
	margin:0 0 20px;
}

.select-credit-list{
	@include list-unstyled;
	@include clearfix();
	margin:0 -10px 25px;
	padding:0;
	font-weight:300;
	font-family:$font-family-custom;
	
	li{
		display:block;
		float:left;
		margin:0 0 25px;
		padding:0 10px;
		width:50%;
		
		a{
			display:block;
			text-align:center;
			padding:8px 10px;
			color:$blue;
			border:1px solid $red;
			text-decoration:none;
			@include transition(all .2s ease-in-out);
		}
		
		&:hover,
		&.active{
			a{
				background-color:$blue;
				color:$white;
				outline:none;
			}
		}
	}
}



/**Buyesrs buying credit End**/


